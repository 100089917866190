import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { orderKeys } from "./keys";
import {
  FreeSingleItemQuantitiesDetails,
  FreeSingleItemQuantitiesListItem,
  ManufacturingItemProgress,
  ManufacturingOrder,
  ManufacturingOrderDetails,
  OrderForInvoicing,
  OrderSingleItemQuantityWarehouseLocation,
  OrderWarehouseLocation,
  OrdersGroupByInfo,
  OrdersSalesAccountInfo,
} from "./models";

const getOrdersForInvoicing = (search: string = ""): ApiFetcher<Pagination<OrderForInvoicing>> => ({
  key: orderKeys.ordersForInvoicing(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/for-invoicing" + search,
    }),
});

const getOrderWarehouseLocations = (
  search: string = "",
): ApiFetcher<Pagination<OrderWarehouseLocation>> => ({
  key: orderKeys.orderWarehouseLocations(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/warehouse-locations" + search,
    }),
});

const getSingleItemQuantityWarehouseLocations = (
  search: string = "",
): ApiFetcher<Pagination<OrderSingleItemQuantityWarehouseLocation>> => ({
  key: orderKeys.singleItemQuantityWarehouseLocations(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/single-item-quantity-warehouse-locations" + search,
    }),
});

const getOrdersFreeSingleItemQuantities = (
  search: string = "",
): ApiFetcher<Pagination<FreeSingleItemQuantitiesListItem>> => ({
  key: orderKeys.ordersFreeSingleItemQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/free-single-item-quantities" + search,
    }),
});

const getOrderFreeSingleItemsQuantitiesDetails = (
  id: UUID,
): ApiFetcher<FreeSingleItemQuantitiesDetails> => ({
  key: orderKeys.orderFreeSingleItemQuantitiesDetails(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/orders/free-single-item-quantities/${id}`,
    }),
});

const getManufacturingOrders = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingOrder>> => ({
  key: orderKeys.manufacturingOrders(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/manufacturing" + search,
    }),
});

const getOrdersGroupByInfo = (search: string = ""): ApiFetcher<OrdersGroupByInfo[]> => ({
  key: orderKeys.ordersGroupByInfo(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/group-by-info" + search,
    }),
});

const getOrdersSalesAccountInfo = (search: string = ""): ApiFetcher<OrdersSalesAccountInfo[]> => ({
  key: orderKeys.ordersSalesAccountInfo(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/sales-account-info" + search,
    }),
});

const getManufacturingOrderDetails = (orderId: number): ApiFetcher<ManufacturingOrderDetails> => ({
  key: orderKeys.manufacturing.orderDetails(orderId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/manufacturing/" + orderId,
    }),
});

const getOrderManufacturingSingleItemQuantities = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingItemProgress>> => ({
  key: orderKeys.orderManufacturingSingleItemQuantities(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/orders/manufacturing-single-item-quantities" + search,
    }),
});

const postAwaitingTransport = (data: { ordersIds: number[] }) =>
  queryFetch<void>({
    method: "POST",
    url: "orders/create-awaiting-transport",
    data,
  });

export const orderApi = {
  getOrderWarehouseLocations,
  getSingleItemQuantityWarehouseLocations,
  getOrdersFreeSingleItemQuantities,
  getOrderFreeSingleItemsQuantitiesDetails,
  getManufacturingOrderDetails,
  getManufacturingOrders,
  getOrdersForInvoicing,
  getOrdersGroupByInfo,
  getOrdersSalesAccountInfo,
  getOrderManufacturingSingleItemQuantities,
  postAwaitingTransport,
};
