import { queryString } from "utilities";
import { useQuery } from "hooks";
import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useCustomerColumns } from "./useCustomerColumns";
import { customerActions } from "api/customers/actions";
import { useNavigate } from "hooks/useNavigate";
import { Customer } from "api/customers/models";

export const CustomerList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query });

  const { data: customers, error, isLoading, pagination } = customerActions.useCustomers(search);
  const navigate = useNavigate();

  const tableProps = useTableFeatureConnector({
    rows: customers,
    withPagination: { pagination, defaultPaginationVisibility: true },
  });

  const columns = useCustomerColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj wśród kontrahentów...",
          tags: [],
        }}
        viewLabel="CUSTOMER_LIST"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<Customer>
          columns={columns}
          isLoading={isLoading}
          error={error}
          uiSchema={mainListUiSchema}
          onRowClick={rowId => navigate(`/crm/customers/${rowId}`)}
          {...tableProps}
        />
      </div>
    </PageWrapper>
  );
};

type GetSearchProps = {
  query: {
    [x: string]: string;
  };
};

const getSearch = ({ query }: GetSearchProps) => {
  return queryString.stringify(query);
};
