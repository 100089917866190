import { shippingActions } from "api/shipping/actions";
import { AuthorizationStatus, Courier } from "api/shipping/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useDrawer } from "hooks/useDrawer";

interface Props {
  courier: Courier;
}

export const PanelHeader = ({ courier }: Props) => {
  const shippingAuthorizeMutation = shippingActions.usePostShippingAuthorize();
  const { close } = useDrawer("shippingCourier");
  const { isTriggerElementHidden } = useScrollableContext();

  return (
    <RightPanelHeader>
      <div className="d-flex align-items-center gap-2">
        {courier.authorizationStatus === AuthorizationStatus.SUCCESS ? (
          <Tag startIcon={MdiCheckSmall} label="Autoryzowano" variant="success" type="outlined" />
        ) : (
          <Button
            size="small"
            variant="outline"
            startIcon={MdiCheckSmall}
            className="text-uppercase"
            onClick={() => shippingAuthorizeMutation.mutate(courier.id)}
          >
            Autoryzuj
          </Button>
        )}
        {isTriggerElementHidden && (
          <SlidingHeaderWrapper className="noWrap">{courier.name}</SlidingHeaderWrapper>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-end flex-1">
        <Tooltip title="Zamknij panel">
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </Tooltip>
      </div>
    </RightPanelHeader>
  );
};
