import apiFetch, { tokenRefreshMiddleware } from "apiConnectors/fetchConnector";
import { Pagination } from "../types";
import {
  OrderGroup,
  ListOrderGroup,
  OrderGroupSettlementSchema,
  OrderGroupSettledDetails,
} from "./models";
import { dateFns, omit } from "utilities";
import { CommentType } from "components/common";
import { orderGroupsKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import { Assign } from "utility-types";
import { RampLoadingStatus } from "api/routes/models";
import { parsePatchData } from "utilities/parsePatchData";

const api = tokenRefreshMiddleware(apiFetch);

export const getOrderGroup = (id: number | string) =>
  api<OrderGroup>({
    method: "GET",
    url: "/order-groups/items/" + id,
  });

export const getOrderGroups = (search = "", abortToken?: string) =>
  api<Pagination<ListOrderGroup>>({
    method: "GET",
    url: "/order-groups/items" + search,
    abortToken,
  });

export const getSimplifiedOrderGroups = (search: string = "", abortToken?: string) =>
  api<Pagination<any>>({
    method: "GET",
    url: "/order-groups/light" + search,
    abortToken,
  });

export const getOrderGroupsQuery = (
  search: string = "",
): ApiFetcher<Pagination<ListOrderGroup>> => ({
  key: orderGroupsKeys.orderGroups(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/order-groups/items" + search,
    }),
});

export const getRampLoadingStatusForOrderGroup = (
  id: number | string,
): ApiFetcher<RampLoadingStatus[]> => ({
  key: orderGroupsKeys.rampLoadingStatus(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/order-groups/ramp-loading-status/" + id,
    }),
});

export const getOrderGroupQuery = (id: string | number): ApiFetcher<OrderGroup> => ({
  key: orderGroupsKeys.orderGroup(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/order-groups/items/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

export const getSettledOrderGroupQuery = (id: string | number): ApiFetcher<OrderGroup> => ({
  key: orderGroupsKeys.orderGroup(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/order-groups/settled-snapshots/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

export const patchOrderGroup = (
  id: number | string,
  data: Partial<Assign<Omit<OrderGroup, "ramp">, { ramp: number | null }>>,
) =>
  api<OrderGroup>({
    method: "PATCH",
    url: "/order-groups/items/" + id,
    data,
  });

export const postComment = (data: { orderGroup: number; body: string }) =>
  api<CommentType>({
    method: "POST",
    url: "/order-groups/comments",
    data,
  });

export const deleteComment = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/order-groups/comments/" + id,
  });

export const patchComment = (comment: Partial<CommentType> & { id: number }) =>
  api<CommentType>({
    method: "PATCH",
    url: "/order-groups/comments/" + comment.id,
    data: omit(comment, ["id"]),
  });

export const postOrderGroup = () =>
  api<
    Pick<
      OrderGroup,
      | "createdBy"
      | "id"
      | "isDraft"
      | "name"
      | "signature"
      | "pickUpDate"
      | "pickUpTime"
      | "warehouseDocument"
    >
  >({
    method: "POST",
    url: "/order-groups/items",
  });

export const postPrintReceiptsForOrderGroups = (data: { orderGroups: number[] }) =>
  queryFetch<{ message: string }>({
    method: "POST",
    url: "/finances/trading-documents/print-receipts-for-order-groups",
    data,
  });

export const assignOrderToGroup = (order: number | string, orderGroup: number | string | null) =>
  api<void>({
    method: "POST",
    url: "/order-groups/assign-order",
    data: {
      order,
      orderGroup,
    },
  });

export const deleteOrderGroup = (id: number) =>
  api<void>({
    method: "DELETE",
    url: "/order-groups/items/" + id,
  });

export const getOrderGroupToSettle = (id: string | number) =>
  api<OrderGroupSettlementSchema>({
    method: "GET",
    url: "/order-groups/to-settle/" + id,
  });

export const getSettledOrderGroup = (id: number | string) =>
  api<OrderGroup>({
    method: "GET",
    url: "/order-groups/settled-snapshots/" + id,
  });

export const getOrderGroupSettledDetails = (id: number | string) =>
  api<OrderGroupSettledDetails>({
    method: "GET",
    url: "/order-groups/settled/" + id,
  });

export const postSettledOrderGroup = (data: {
  orderGroup: number;
  amount: number;
  currency: string;
  delivered: { [key: number]: boolean };
  visited: { [key: number]: boolean };
  summary: {
    cashToCollect: number;
    deliveredPointsTotalAmount: number;
    numPointsDelivered: number;
    numPointsNotDelivered: number;
    numPointsVisited: number;
    onlinePaymentTotalAmount: number;
  };
}) =>
  api<OrderGroup>({
    method: "POST",
    url: "/order-groups/settled",
    data,
  });

export const orderGroupFileFactory = (() => {
  function formatSignature(signature: string) {
    return signature.replace(/\//g, "-");
  }
  function getDate() {
    return dateFns.format(new Date(), "dd-MM-yyyy-HH-mm");
  }

  return {
    orderGroupProductionPdf: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `/order-groups/items/${orderGroup.id}/production-pdf`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupPdf: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `/order-groups/items/${orderGroup.id}/group-order-pdf`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupLabelsPdf: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `/utils/print?orderGroup=${orderGroup.id}`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupItemsSummary: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `/order-groups/items/${orderGroup.id}/items-summary-pdf`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupPackingSlip: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `utils/packing-slip/order-group/${orderGroup.id}/download`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupPackingSlipGroupedByOrders: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `utils/packing-slip-grouped-by-orders/order-group/${orderGroup.id}/download`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupDeliveryAmountSummary: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `utils/document-with-delivery-amount/order-group/${orderGroup.id}`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
    orderGroupOrdersCsv: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `/shipping/order-group/${orderGroup.id}/generate-csv`,
      name: `zamowienia-dla-grupy_${formatSignature(orderGroup.signature)}_${getDate()}`,
    }),
    orderGroupMiloOrdersCsv: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `order-groups/external-transport-export-orders-via-milo-csv?orderGroup=${orderGroup.id}`,
      name: `zamowienia-milo-dla-grupy_${formatSignature(orderGroup.signature)}_${getDate()}`,
    }),
    orderGroupOrdersXml: (orderGroup: Pick<OrderGroup, "id" | "signature">) => ({
      url: `/order-groups/items/${orderGroup.id}/xml`,
      name: `${formatSignature(orderGroup.signature)}-${getDate()}`,
    }),
  };
})();

const patchOrderGroupQuery = (data: Assign<Partial<OrderGroup>, { id: OrderGroup["id"] }>) =>
  queryFetch<OrderGroup>({
    method: "PATCH",
    url: "/order-groups/items/" + data.id,
    data: parsePatchData(data),
  });

const getOrderGroupPositions = (
  id: number,
): ApiFetcher<{
  next: number | null;
  previous: number | null;
}> => ({
  key: orderGroupsKeys.orderGroupPositions(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/order-groups/items/get-order-group-neighbor-ids?order_group_id=${id}`,
    }),
});

export const orderGroupApi = {
  patchOrderGroupQuery,
  getOrderGroupPositions,
};
