import { FinancesAmountSummary } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { ToggleHookState } from "hooks";
import { ErrorType } from "hooks/createApiQuery";
import { AmountSummaryBottomBar } from "pages/tradingDocuments/shared/components/amountSummaryBottomBar/AmountSummaryBottomBar";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  amountSummaryIsLoading: boolean;
  amountSummaryModal: ToggleHookState;
  error: ErrorType | null;
}

export const BottomBarOptions = ({
  amountSummary,
  amountSummaryIsLoading,
  amountSummaryModal,
  error,
}: Props) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end gap-2">
        <AmountSummaryBottomBar
          amountSummary={amountSummary}
          amountSummaryIsLoading={amountSummaryIsLoading}
          error={error}
          invoiceType="ADVANCE"
        />
        <Button
          className="text-uppercase"
          disabled={amountSummaryIsLoading || Boolean(error)}
          endIcon={MdiArrowForward}
          onClick={amountSummaryModal.open}
          size="small"
          variant="gray"
        >
          Szczegóły
        </Button>
        <span className="line-divider lineDividerMargin" />
      </div>
    </div>
  );
};
