import { QueryFetchError } from "api/types";
import { FormikHelpers } from "formik";

export const handleSubmitResponse = <T>(
  actions: FormikHelpers<T>,
  otherActions?: {
    success?: () => void;
    error?: () => void;
  },
) => {
  return {
    onSuccess: () => {
      actions.setSubmitting(false);
      otherActions?.success?.();
    },
    onError: (error: QueryFetchError) => {
      actions.setSubmitting(false);
      actions.setErrors(error.response?.data);
      otherActions?.error?.();
    },
  };
};
