import { FormikHelpers } from "formik";
import { AddOrdersToUnloadingForm } from "./AddOrdersBySignaturesModal";
import { useMutation } from "hooks/useMutation";
import { addOrdersToUnloadingBaseOnExternalIds } from "api/wms/calls";
import { AddOrdersToUnloadingResponse, UnloadingDetails } from "api/wms/models";
import { getAnyErrorKey } from "utilities";

export const useAddOrdersToUnloading = (
  responseModal: {
    isOpen: boolean;
    open: (stateToSet: AddOrdersToUnloadingResponse) => void;
    close: () => void;
    state: AddOrdersToUnloadingResponse | null;
  },
  unloading: UnloadingDetails,
) => {
  const addOrdersToUnloadingMutation = useMutation(
    addOrdersToUnloadingBaseOnExternalIds,
    ({ toastr, queryClient }) => ({
      onSuccess: payload => {
        queryClient.invalidateQueries();
        responseModal.open(payload);
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddOrdersToUnloadingForm,
    actions: FormikHelpers<AddOrdersToUnloadingForm>,
  ) => {
    const orderRegex = /\b[^,\n ]+(?=[,\s\n]|$)/g;
    const parsedOrders = values.orders.match(orderRegex);
    addOrdersToUnloadingMutation.mutate(
      {
        ordersIds: parsedOrders || [],
        unloadingId: unloading.id,
      },
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return handleSubmit;
};
