import { Switch, RouteComponentProps } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { CustomerList } from "pages/crm/customers/customerList";
import { CustomerDetails } from "pages/crm/customers/customerDetails";

export const CustomersRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        component={CustomerList}
        title="Milo - kontrahenci"
      />
      <Route
        exact={true}
        path={`${match.path}/:customerId`}
        component={CustomerDetails}
        title="Milo - profil kontrahenta"
      />

      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
