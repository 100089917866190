import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Formik } from "formik";
import { cx } from "utilities";
import { validationSchema } from "./validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import { TextField } from "components/miloDesignSystem/atoms/textField";
import { manufacturersActions } from "api/manufacturers/actions";
import { GetDataFromGusModal } from "components/common/getDataFromGusModal/GetDataFromGusModal";
import { useSelector, useToastr, useToggle } from "hooks";
import { ManufacturerKind } from "api/manufacturers/models";
import { UUID } from "api/types";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";

interface Props {
  close: () => void;
}

export interface CreateServiceProviderSchema {
  name: string;
  fullName: string;
  email: string;
  taxId: string;
  kind: ManufacturerKind;
  schemaStages: UUID[];
}

export const CreateServiceProvider = ({ close }: Props) => {
  const handleSubmit = manufacturersActions.usePostManufacturer(close);
  const gusModal = useToggle();
  const toastr = useToastr();
  const stages = useSelector(store => store.partials.stages);

  const initialValues: CreateServiceProviderSchema = {
    name: "",
    email: "",
    fullName: "",
    taxId: "",
    kind: ManufacturerKind.SERVICE_PROVIDER,
    schemaStages: [],
  };

  return (
    <Modal
      close={close}
      isOpen
      width={480}
      title={
        <Typography fontSize="20" fontWeight="700">
          Utwórz podwykonawcę
        </Typography>
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, setValues }) => (
          <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
            <div className="d-flex gap-3 justify-content-between px-3 pb-3">
              <TextField.Form<CreateServiceProviderSchema>
                containerClassName="flex-1"
                label="NIP"
                name="taxId"
                placeholder="Wpisz NIP"
              />

              <Button
                variant="gray"
                size="medium"
                className="mt-2"
                onClick={() => {
                  if (!values.taxId) {
                    toastr.open({
                      title: "Brak NIP",
                      text: "W celu pobrania danych z GUS uzupełnij NIP",
                      type: "warning",
                    });
                    return;
                  }
                  gusModal.open();
                }}
              >
                Pobierz dane z GUS
              </Button>

              {gusModal.isOpen && (
                <GetDataFromGusModal
                  modal={gusModal}
                  taxId={values.taxId}
                  onConfirmClick={data =>
                    setValues({
                      ...values,
                      ...data,
                    })
                  }
                />
              )}
            </div>
            <div className="px-3 pb-3">
              <TextField.Form<CreateServiceProviderSchema>
                label="Pełna nazwa"
                name="fullName"
                placeholder="Wpisz pełną nazwę"
                helperText="Będzie używana do dokumentów finansowych i w oficjalnej korespondencji"
              />
            </div>
            <div className="px-3 pb-3">
              <TextField.Form<CreateServiceProviderSchema>
                label="Nazwa skrócona"
                name="name"
                placeholder="Wpisz nazwę skróconą"
                helperText="Będzie wyświetlana w aplikacji"
              />
            </div>
            <div className="px-3 pb-3">
              <TextField.Form<CreateServiceProviderSchema>
                label="Email"
                name="email"
                placeholder="Wpisz email"
              />
            </div>
            <div className="px-3 pb-3">
              <div className="pb-2">
                <Typography
                  className="text-uppercase"
                  color="neutralBlack48"
                  fontSize="10"
                  fontWeight="700"
                >
                  Etapy, w których bierze udział
                </Typography>
              </div>
              <div className="d-flex flex-column gap-2">
                {stages.map(stage => (
                  <div className="d-flex align-items-center mb-2">
                    <Checkbox
                      checked={values.schemaStages.includes(stage.id)}
                      label={stage.name}
                      onChange={() => {
                        if (values.schemaStages.includes(stage.id))
                          setValues({
                            ...values,
                            schemaStages: values.schemaStages.filter(
                              schemaStage => schemaStage !== stage.id,
                            ),
                          });
                        else
                          setValues({
                            ...values,
                            schemaStages: [...values.schemaStages, stage.id],
                          });
                      }}
                      size="sm"
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="d-flex align-items-center gap-2 p-3 borderTop">
              <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
                Anuluj
              </Button>
              <Button
                className="text-uppercase"
                isLoading={isSubmitting}
                size="medium"
                type="submit"
                variant="deepPurple"
              >
                Dodaj
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
