import { FinancesAmountSummary } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { ToggleHookState } from "hooks";
import { ErrorType } from "hooks/createApiQuery";
import { AmountSummaryBottomBar } from "pages/tradingDocuments/shared/components/amountSummaryBottomBar/AmountSummaryBottomBar";

interface Props {
  amountSummary: FinancesAmountSummary | null;
  isLoading: boolean;
  error: ErrorType | null;
  uploadPurchaseInvoicePdf: ToggleHookState;
}

export const BottomBarOptions = ({
  amountSummary,
  isLoading,
  error,
  uploadPurchaseInvoicePdf,
}: Props) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end gap-2">
        <AmountSummaryBottomBar
          amountSummary={amountSummary}
          amountSummaryIsLoading={isLoading}
          error={error}
        />
        <span className="line-divider lineDividerMargin" />
        <Button
          className="text-uppercase"
          onClick={uploadPurchaseInvoicePdf.open}
          size="small"
          startIcon={MdiAdd}
          variant="gray"
        >
          Importuj fakturę
        </Button>
      </div>
    </div>
  );
};
