import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { financesOverviewApi, financesOverviewFileFactory } from "./api";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";
import { indexFileFactory } from "api/indexes/calls";

const useSoldPackages = createPaginatedApiQuery(financesOverviewApi.getSoldPackages);

const useSoldIndexes = createPaginatedApiQuery(financesOverviewApi.getSoldIndexes);

const useDownloadSoldPackages = (search: string) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async () => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = financesOverviewFileFactory.soldPackagesCsv(search);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.lazyClose();
      handleErrorMessage(response);
    }
  };
};

const useDownloadSoldIndexesCsv = (search: string) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async () => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = financesOverviewFileFactory.soldIndexesCsv(search);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.lazyClose();
      handleErrorMessage(response);
    }
  };
};

const useDownloadIndexesPricesCsv = (close: () => void) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (search: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = indexFileFactory.indexesWithPackagesCsv(search);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });
    if (response.status === "success") {
      tstr.lazyClose();
      close();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadPackagesPricesCsv = (close: () => void) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (search: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const { url, name } = indexFileFactory.packagesWithPricesAndStateCsv(search);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });
    if (response.status === "success") {
      tstr.lazyClose();
      close();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

export const financesOverviewActions = {
  useSoldPackages,
  useSoldIndexes,
  useDownloadIndexesPricesCsv,
  useDownloadPackagesPricesCsv,
  useDownloadSoldPackages,
  useDownloadSoldIndexesCsv,
};
