import { TradingDocument } from "api/trading-documents/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../shared/TradingDocumentsLists.module.css";
import cx from "classnames";
import { receiptListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { StateLabel } from "components/common/stateLabel";
import { dateFns } from "utilities";
import localStyles from "../../tradingDocumentsList/TradingDocumentsList.module.css";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "pages/tradingDocuments/shared/utils/isDateOlderThan24Hours";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { UUID } from "api/types";
import { HighlightedRow } from "api/other/models";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  receipt: TradingDocument;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  receipt,
  selectMutlipleItems,
  togglePanel,
}: Props) => {
  return (
    <ModuleRow
      className={cx({
        active: isActive(receipt.id),
        highlighted:
          isHighlighted(receipt.id) || highlightedRows.some(row => row.id === receipt.id),
      })}
      gridStyle={receiptListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, receipt.id, index, () => togglePanel(receipt.id))
      }
    >
      <div>
        <ConfirmCheckbox tradingDocument={receipt} />
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={styles.signature}>{receipt.signature ? receipt.signature : "---"}</div>
        {!isDateOlderThan48Hours(receipt.createdAt) && (
          <StateLabel
            kind={receipt.status === "CONFIRMED" ? "default" : "green"}
            opacity={
              receipt.createdAt && isDateOlderThan24Hours(receipt.createdAt) ? "high" : "default"
            }
          >
            {tradingDocumentConstants.tradingDocumentStatusDict[receipt.status]}
          </StateLabel>
        )}
      </div>
      <div className="fw-700">
        {receipt.printingStatus === "NOT_STARTED" && (
          <StateLabel className="bg-teal-4" kind="default">
            {tradingDocumentConstants.receiptPrintingStatusDict[receipt.printingStatus]}
          </StateLabel>
        )}
        {receipt.printingStatus === "IN_PROGRESS" && (
          <StateLabel kind="violetWhite">
            {tradingDocumentConstants.receiptPrintingStatusDict[receipt.printingStatus]}
          </StateLabel>
        )}
        {receipt.printingStatus === "FINISHED" && (
          <StateLabel kind="blue">
            {tradingDocumentConstants.receiptPrintingStatusDict[receipt.printingStatus]}
          </StateLabel>
        )}
        {receipt.printingStatus === "FAILED" && (
          <StateLabel className="bg-deep-orange-3" kind="default">
            {tradingDocumentConstants.receiptPrintingStatusDict[receipt.printingStatus]}
          </StateLabel>
        )}
      </div>
      <div className={cx(styles.signature, "d-flex align-items-center")}>
        {receipt.orders.slice(0, 1).map(order => {
          return order ? order.signature : "";
        })}{" "}
        &nbsp;
        {receipt.orders.filter(order => order).length - 1 > 0 ? (
          <MoreDataCounter
            className={styles.moreDataCounterOverride}
            counter={receipt.orders.filter(order => order).length - 1}
            style={{ width: "max-content" }}
          />
        ) : null}
      </div>
      {receipt.amountSummary.totalWithTax ? (
        <div className={cx(styles.amountToPay, "d-flex align-items-center")}>
          <FinanceAmountFormat value={receipt.amountSummary.totalWithTax} />
          <div className="fs-10 text-color-grey">{receipt.currency}</div>
        </div>
      ) : (
        <Typography fontSize="12" fontWeight="600">
          brak
        </Typography>
      )}
      <div className={styles.date}>
        {receipt.invoicePaymentDate
          ? dateFns.format(new Date(receipt.invoicePaymentDate), "dd.MM.yyyy")
          : "---"}
      </div>
      <div className={styles.date}>
        {receipt.invoiceIssueDate
          ? dateFns.format(new Date(receipt.invoiceIssueDate), "dd.MM.yyyy")
          : "---"}
      </div>
      <div>
        {receipt.printingRequestedBy && (
          <div className="d-flex align-items-center gap-1">
            {receipt.printingRequestedBy.avatar ? (
              <div className={cx(localStyles.listAvatar, localStyles.avatarSmall)}>
                <img alt="avatar" src={receipt.printingRequestedBy.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(localStyles.listAvatar, localStyles.avatarSmall)}
                firstName={receipt.printingRequestedBy.firstName}
                lastName={receipt.printingRequestedBy.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={localStyles.assignee}>
              {receipt.printingRequestedBy.firstName} {receipt.printingRequestedBy.lastName}
            </div>
          </div>
        )}
      </div>
      <div className={styles.date}>
        {receipt.printingRequestedAt
          ? dateFns.format(new Date(receipt.printingRequestedAt), "dd.MM.yyyy")
          : "---"}
      </div>
      <div>
        {receipt.assignedTo && (
          <div className="d-flex align-items-center gap-1">
            {receipt.assignedTo.avatar ? (
              <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                <img alt="avatar" src={receipt.assignedTo.avatar} />
              </div>
            ) : (
              <InitialsAvatar
                className={cx(styles.listAvatar, styles.avatarSmall)}
                firstName={receipt.assignedTo.firstName}
                lastName={receipt.assignedTo.lastName}
                style={{ margin: 0 }}
              />
            )}
            <div className={styles.assignee}>
              {receipt.assignedTo.firstName} {receipt.assignedTo.lastName}
            </div>
          </div>
        )}
      </div>
      <div className={styles.subject}>
        {receipt.sellerCompanyName ? (
          receipt.sellerCompanyName
        ) : (
          <>
            {receipt.sellerFirstName} {receipt.sellerLastName}
          </>
        )}
      </div>
    </ModuleRow>
  );
};
