import cx from "classnames";
import { memo } from "react";
import styles from "../Navbar.module.css";
import toggleIcon from "assets/images/17w.svg";
import { NavLink, useRouteMatch } from "react-router-dom";
import { Toggle } from "components/utils";
import { useSelector } from "hooks";
import { arrayToDict } from "utilities";
import { routePaths } from "routePaths";

interface Props {
  search: string;
}

export const OrdersMenu = memo(({ search }: Props) => {
  const match = useRouteMatch<{ tab: string; navbarTab: string }>("/orders/list/:tab/:navbarTab");
  const tab = match?.params.tab || "active";
  const salesAccount = match?.params.navbarTab;
  // we want to preserve search when user changes between order views,
  // but don't between others
  const preservedOrderSearch = salesAccount ? search : "";
  const salesAccounts = useSelector(state => state.partials.salesAccounts);
  const counters = useSelector(state =>
    arrayToDict(state.partials.counters, { name: "salesAccountId" }),
  );

  return (
    <div className={styles.navLeftList}>
      <div className={cx(styles.navLeftLink, styles.navLeftLinkHasList)}>
        <NavLink
          to={routePaths.orderList("active", "all")}
          className="pl-3 pr-3"
          activeClassName={styles.active}
        >
          <strong>Zamówienia</strong>
        </NavLink>
        <Toggle>
          {({ isOpen, toggle }) => (
            <>
              <button
                type="button"
                className={cx(styles.toggleBtn, {
                  [styles.toggleBtnClosed]: !isOpen,
                })}
                onClick={toggle}
              >
                <img src={toggleIcon} alt="Otwiera i zamyka listę" />
              </button>
              {isOpen && (
                <div className={styles.navLeftInnerList}>
                  <div className={styles.navLeftSubLink}>
                    <NavLink
                      to={routePaths.orderList(tab, "all") + preservedOrderSearch}
                      className="pl-3 pr-3 active"
                      activeClassName={styles.active}
                    >
                      <span className={styles.navLeftLabel} />
                      <strong>wszystkie</strong>
                    </NavLink>
                    <NavLink
                      to={
                        routePaths.orderList(tab, "not-all-items-added-to-manufacturing-order") +
                        preservedOrderSearch
                      }
                      className="pl-3 pr-3 active"
                      activeClassName={styles.active}
                    >
                      <span className={styles.navLeftLabel} />
                      <strong>niezlecone</strong>
                    </NavLink>
                    <NavLink
                      to={
                        routePaths.orderList(tab, "all-items-added-to-manufacturing-order") +
                        preservedOrderSearch
                      }
                      className="pl-3 pr-3 active"
                      activeClassName={styles.active}
                    >
                      <span className={styles.navLeftLabel} />
                      <strong>zlecone</strong>
                    </NavLink>
                  </div>
                  {salesAccounts.map(salesAccount => (
                    <div className={styles.navLeftSubLink} key={salesAccount.id}>
                      <NavLink
                        to={routePaths.orderList(tab, salesAccount.id) + preservedOrderSearch}
                        className="pl-3 pr-3"
                        activeClassName={styles.active}
                      >
                        <span
                          className={cx(styles.navLeftLabel)}
                          style={{ backgroundColor: salesAccount.color }}
                        ></span>
                        <strong>{salesAccount.name}</strong>
                        <span className={styles.orderCounter}>
                          {counters[salesAccount.id]?.numOrders ?? 0}
                        </span>
                      </NavLink>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </Toggle>
      </div>
    </div>
  );
});
