import { Channels } from "CONSTANTS";
import { ISODate, ISODateTime, UUID } from "api/types";
import { UserWithInitials } from "api/users/models";
import { ShippingService } from "constants/shippingService";
import { Assign } from "utility-types";

export type Priority = 0 | 1 | 2;

export type ManufacturingItemKind = "PRODUCT" | "PACKAGE";

export enum EmailStatus {
  OPENED = "OPENED",
  SENT = "SENT",
  DECLINED = "DECLINED",
  CLICKED_LINK = "CLICKED_LINK",
}

export enum NotificationAction {
  REQUEST_PRODUCTION = "REQUEST_PRODUCTION",
  REMIND_ABOUT_DEADLINE = "REMIND_ABOUT_DEADLINE",
  ASK_FOR_STATUS = "ASK_FOR_STATUS",
}

export enum ExternalManufacturerItemsTab {
  "not-sent" = "not-sent",
  "sent" = "sent",
  "in-stock" = "in-stock",
  "cancelled" = "cancelled",
  "all" = "all",
}

export enum ExternalManufacturerLineItemsTab {
  "to-do" = "to-do",
  "in-production" = "in-production",
  "ready-to-deliver" = "ready-to-deliver",
  "picked-up" = "picked-up",
  "cancelled" = "cancelled",
  "all" = "all",
}

interface Label {
  id: string;
  code: string;
  name: string;
  total: number;
  position: number;
  kindCode: string;
  parameters: {
    width: number;
    height: number;
    length: number;
    weight: number;
  };
  description: string;
  uniqueCode: string;
}

interface Address {
  city: string;
  countryCode: string;
  postCode: string;
  street: string;
}

interface ManufacturerDetails {
  email: string;
  id: number;
  name: string;
}

export interface Notification {
  id: string | number;
  action: NotificationAction;
  date: string | null;
  error: string;
  recipient: string;
  sentBy: UserWithInitials | null;
  status: EmailStatus | null;
}

export interface OrderLineItemDetails {
  id: UUID;
  signature: string;
  isCancelled: boolean;
  name: string;
  manufacturerName: string;
  order: ExternalManufacturerLineItem["order"];
  priority: number;
  orderedAt: ISODateTime | null;
  orderStatus: OrderStatus;
  isNew: boolean;
  executionStatus: ExecutionStatus;
  pickedUpBySeller: boolean;
  manufacturingOrder: {
    id: UUID;
    signature: string;
  };
  deliveryGroup: ExternalManufacturerLineItem["deliveryGroup"];
  estimatedDeliveryDate: ISODate | null;
  deliveredAt: ISODateTime | null;
  orderedBy: UserWithInitials;
  hasConfirmedDeliveryDate: boolean;
  deliveryDateConfirmedAt: ISODateTime | null;
  deliveryDateConfirmedBy: UserWithInitials | null;
  labels: Label[];
}

export interface ExternalManufacturingOrder {
  createdAt: ISODateTime;
  deliveredToWarehouseAt: ISODateTime | null;
  emailSentStatus: EmailSentStatus;
  estimatedTimeOfSendingEmail: string | null;
  expectedWarehouseDeliveryDate: ISODate | null;
  fulfillmentAt: ISODateTime | null;
  id: UUID;
  lineItems: {
    inProgress: number;
    notStarted: number;
    readyToDeliver: number;
    total: number;
  };
  manufacturer: { id: number; name: string };
  name: string;
  notificationsSent: Notification[];
  orderStatus: OrderStatus;
  orderedBy: UserWithInitials[];
  orders: {
    description: string;
    externalId: string;
    id: number;
    signature: string;
  }[];
  priority: Priority;
  progress: number;
  qrCode: string;
  sentAt: ISODateTime | null;
  signature: string;
  updatedAt: ISODateTime | null;
}

export enum PriorityChoice {
  LOW = 0,
  MEDIUM = 1,
  HIGH = 2,
}

export enum EmailSentStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  DELIVERED = "DELIVERED",
  SEEN = "SEEN",
  ERROR = "ERROR",
}

export enum OrderStatus {
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  IN_STOCK = "IN_STOCK",
}

export enum ExecutionStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  READY_TO_DELIVER = "READY_TO_DELIVER",
}

export enum ShipmentKind {
  OWN_TRANSPORT = "OWN_TRANSPORT",
  COURIER = "COURIER",
  SELF_PICKUP = "SELF_PICKUP",
}

export enum NotificationType {
  ASK_FOR_STATUS = "ASK_FOR_STATUS",
}

export interface Shipment {
  kind: ShipmentKind;
  name: string;
}

interface ShippingInfo {
  pickedUpAt: ISODateTime | null;
  pickedUpBySeller: boolean;
  pickupBySellerPlannedAt: string | null;
  shippingService: {
    id: string | number;
    kind: ShipmentKind;
    provider: ShippingService | "";
    name: string;
  } | null;
}

export interface ExternalManufacturerLineItem {
  address: Address;
  buyer: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  createdAt: ISODateTime;
  deliveredAt: ISODateTime | null;
  deliveryDateConfirmedAt: ISODateTime | null;
  deliveryDateConfirmedBy: UserWithInitials;
  deliveryDeadlineDate: ISODateTime | null;
  deliveryGroup: {
    description: string;
    id: number;
    kind: "ROUTE" | "ORDER_GROUP";
    signature: string;
  };
  estimatedDeliveryDate: ISODateTime | null;
  executionStatus: ExecutionStatus;
  hasConfirmedDeliveryDate: boolean;
  id: UUID;
  isCancelled: boolean;
  labels: Label[];
  manufacturerName: string;
  name: string;
  order: {
    description: string;
    externalId: string;
    id: number;
    signature: string;
  };
  orderStatus: OrderStatus;
  orderedAt: ISODateTime | null;
  orderedBy: UserWithInitials;
  pickedUpAt: ISODateTime | null;
  pickedUpBySeller: boolean;
  position: number;
  priority: Priority;
  isNew: boolean;
  product: {
    attributes: {
      id: number;
      name: string;
      value: string;
    }[];
    manufacturerName: string;
    name: string;
  };
  shipment: Shipment | null;
  shippingInfo: ShippingInfo | null;
  signature: string;
}

export interface OrderLineItemListItem {
  isNew: boolean;
  address: Address;
  buyer: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  id: UUID;
  position: number;
  signature: string;
  manufacturingOrder: {
    fulfillmentAt: ISODate | null;
    id: UUID;
    signature: string;
  };
  deliveryDeadlineDate: ISODate | null;
  name: string;
  manufacturerName: string;
  salesChannel: {
    name: Channels;
    textColor: string;
  };
  manufacturer: {
    id: number;
    name: string;
  };
  description: string;
  order: {
    id: number;
    signature: string;
    description: string;
    externalId: string;
  };
  orderedBy: UserWithInitials;
  orderedAt: ISODateTime | null;
  priority: PriorityChoice;
  product: {
    attributes: {
      id: number;
      name: string;
      value: string;
    }[];
    manufacturerName: string;
    name: string;
  };
  seller: {
    id: UUID;
    name: string;
    address: string;
  };
  supplier: {
    id: number;
    name: string;
  };
  isCancelled: number;
  executionStatus: ExecutionStatus;
  labels: Label[];
  orderStatus: OrderStatus;
  deliveryGroup: {
    id: number;
    kind: "ROUTE" | "ORDER_GROUP";
    signature: string;
    description: string;
    loadingDate: ISODate | null;
  };
  estimatedDeliveryDate: ISODate | null;
  fulfillmentAt: ISODateTime | null;
  deliveredAt: ISODateTime | null;
  createdAt: ISODateTime;
  shippingInfo: ShippingInfo | null;
}

export interface ExternalManufacturerItem {
  createdAt: ISODateTime;
  deliveredToWarehouseAt: ISODateTime | null;
  emailSentStatus: EmailSentStatus;
  estimatedTimeOfSendingEmail: string | null;
  expectedWarehouseDeliveryDate: ISODate | null;
  fulfillmentAt: ISODateTime | null;
  id: UUID;
  name: string;
  notificationsSent: Notification[];
  numOfLineItems: {
    inProgress: number;
    notStarted: number;
    readyToDeliver: number;
    total: number;
  };
  orderStatus: OrderStatus;
  orderedBy: UserWithInitials[];
  orders: {
    description: string;
    externalId: string;
    id: number;
    signature: string;
  }[];
  priority: Priority;
  progress: number;
  qrCode: string;
  sentAt: ISODateTime | null;
  signature: string;
}

export interface SendNotificationToRecipient {
  orderId: UUID;
  recipientEmail?: string;
}

export interface AskForSingleProductionItemsStatus {
  notificationType: NotificationType;
  singleProductionItems: UUID[];
}

export interface AskForSingleProductionItemsStatusFiltered {
  notificationType: NotificationType;
  route: string | number;
}

interface OrderDetails {
  deliveryNoLaterThan: ISODateTime | null;
  externalId: string;
  id: number;
  placedAt: ISODateTime | null;
  shipmentTime: number;
  signature: string;
}

export enum PaymentSource {
  ONLINE = "ONLINE",
  ON_DELIVERY = "ON_DELIVERY",
}

export enum PaymentStatus {
  PAID = "PAID",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  UNPAID = "UNPAID",
  OVERPAID = "OVERPAID",
}

export interface CreateManufacturingOrderItem {
  id: UUID;
  details: {
    addedToManufacturingOrder: boolean;
    addedToManufacturingOrderAt: ISODateTime | null;
    addedBy: Assign<UserWithInitials, { initials: string; initialsTextColor: string }> | null;
  };
  payment: {
    type: PaymentSource;
    status: PaymentStatus;
  };
  product: {
    name: string;
    attributes: {
      id: number;
      name: string;
      value: string;
    }[];
    manufacturerName: string;
  };
  manufacturer: ManufacturerDetails;
  salesChannel: {
    name: Channels;
    textColor: string;
  };
  description: string;
  order: OrderDetails;
  orderedBy: Assign<UserWithInitials, { initials: string; initialsTextColor: string }> | null;
  orderedAt: ISODateTime | null;
  priority: Priority;
  reservation: {
    created: ISODateTime;
    id: number;
    signature: string;
  } | null;
  seller: {
    id: number;
    name: string;
    address: Address;
  };
  supplier: {
    id: number;
    name: string;
  };
  address: Address;
  buyer: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  labels: Assign<Omit<Label, "id">, { id: string | number }>[];
  deliveryGroup: {
    id: number;
    kind: "ROUTE" | "ORDER_GROUP";
    signature: string;
    description: string;
  } | null;
  estimatedDeliveryDate: string | null;
  kind: ManufacturingItemKind;
  version: number;
  letter: string;
}

export enum ScheduleEmailStatus {
  NOT_SENT = "NOT_SENT",
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
}

export enum ScheduleConfirmationStatus {
  NOT_SET = "NOT_SET",
  CONFIRMED = "CONFIRMED",
  REJECTED = "REJECTED",
}

export interface EmailsSchedule {
  id: UUID | number;
  manufacturer: ManufacturerDetails;
  quantity: number;
  date: ISODateTime | null;
}

export interface RouteLineItem {
  id: UUID;
  manufacturer: ManufacturerDetails;
  name: string;
  order: OrderDetails | null;
  pickupBySellerPlannedAt: ISODateTime | null;
  product: {
    attributes: {
      id: number;
      name: string;
      value: string;
    }[];
    manufacturerName: string;
    name: string;
  };
  scheduleEmailStatus: ScheduleEmailStatus;
  scheduleEmailSentAt: ISODateTime | null;
  scheduleConfirmationStatus: ScheduleConfirmationStatus;
  signature: string;
}

export enum UnsentEmailReason {
  MANUFACTURING_ORDER_PRODUCTION_NOT_REQUESTED = "MANUFACTURING_ORDER_PRODUCTION_NOT_REQUESTED",
  OTHER = "OTHER",
}

export type UnsentEmailPreview = {
  sentEmails: {
    message: string;
    objects: string[];
  };
  unsentEmails: Record<
    UnsentEmailReason,
    {
      message: string;
      objects: {
        signature: string;
      }[];
    }
  >;
};
