import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import styles from "../RightPanel.module.css";
import { DatePicker } from "components/utils/datePicker";
import { dateFns } from "utilities";
import { ExecutionStatus, OrderLineItemDetails } from "api/external-manufacturing/models";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Link } from "components/miloDesignSystem/atoms/link";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Select } from "components/miloDesignSystem/molecules/select";

interface Props {
  lineItemDetails: OrderLineItemDetails;
}

const executionStatusDict: Record<ExecutionStatus, string> = {
  NOT_STARTED: "Nie rozpoczęto",
  IN_PROGRESS: "W produkcji",
  READY_TO_DELIVER: "Gotowe do odbioru",
};

const executionStatusOptions: {
  value: string;
  text: string;
  type: MenuItemType;
}[] = Object.entries(executionStatusDict).map(([value, text]) => ({
  value,
  text,
  type: MenuItemType.TEXT,
}));

export const GeneralInfoSection = ({ lineItemDetails }: Props) => {
  const estimatedDeliveryDateMutation = externalManufacturingActions.usePatchOrderLineItem();
  const executionStatusMutation = externalManufacturingActions.usePatchOrderLineItem();
  const postConfirmDeliveryDate = externalManufacturingActions.usePostConfirmDeliveryDate();

  return (
    <RightPanelSection padding="px-3 pt-0 pb-2">
      <div className="d-flex align-items-start justify-content-between gap-2 ">
        <div className="flex-1">
          <InfoLabel title="zamówienie">
            {lineItemDetails.order ? (
              <Link
                fontSize="14"
                fontWeight="700"
                to={`/orders/active/list/all?panelId=${lineItemDetails.order.id}`}
              >
                {lineItemDetails.order.signature}
              </Link>
            ) : (
              <EmptyValue />
            )}
          </InfoLabel>
          <InfoLabel title="nazwa produktu">
            <Typography fontSize="14" fontWeight="700">
              {lineItemDetails.name}
            </Typography>
          </InfoLabel>
          <InfoLabel title="nazwa produktu u sprzedawcy">
            <Typography fontSize="14" fontWeight="700">
              {lineItemDetails.manufacturerName}
            </Typography>
          </InfoLabel>
          <InfoLabel title="data odbioru">
            <div className="d-flex align-items-center gap-2">
              <DatePicker
                disabled={estimatedDeliveryDateMutation.isLoading}
                look="common"
                overwrites={{
                  popup: { className: styles.datePickerPopup },
                  input: { className: styles.datePickerInput },
                  container: { className: styles.datePickerContainer },
                }}
                value={lineItemDetails.estimatedDeliveryDate || ""}
                onChange={date => {
                  if (!date) {
                    return estimatedDeliveryDateMutation.mutate({
                      id: lineItemDetails.id,
                      toUpdate: {
                        estimatedDeliveryDate: null,
                      },
                    });
                  }
                  return estimatedDeliveryDateMutation.mutate({
                    id: lineItemDetails.id,
                    toUpdate: {
                      estimatedDeliveryDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                    },
                  });
                }}
              />
              {lineItemDetails.hasConfirmedDeliveryDate ? (
                <div className="d-flex align-items-center gap-2">
                  <div className="d-flex align-items-center justify-content-end gap-1">
                    <Avatar
                      size="sm"
                      theme="light"
                      user={lineItemDetails.deliveryDateConfirmedBy}
                    />
                    <div className="d-flex flex-column">
                      <Typography fontSize="12" fontWeight="500">
                        {lineItemDetails.deliveryDateConfirmedBy?.firstName}{" "}
                        {lineItemDetails.deliveryDateConfirmedBy?.lastName}
                      </Typography>
                      {lineItemDetails.deliveryDateConfirmedAt && (
                        <Typography fontSize="10" fontWeight="500" color="neutralBlack64">
                          {dateFns.format(
                            new Date(lineItemDetails.deliveryDateConfirmedAt),
                            "dd.MM.yyyy, HH:mm",
                          )}
                        </Typography>
                      )}
                    </div>
                  </div>
                  <Tag label="Potwierdzono" variant="success" />
                </div>
              ) : (
                <Button
                  size="small"
                  variant="success"
                  startIcon={MdiCheck}
                  disabled={postConfirmDeliveryDate.isLoading}
                  onClick={() => {
                    postConfirmDeliveryDate.mutate(lineItemDetails.id);
                  }}
                >
                  Potwierdź datę odbioru
                </Button>
              )}
            </div>
          </InfoLabel>
          <InfoLabel title="status realizacji">
            <Select
              items={executionStatusOptions}
              onChange={status => {
                if (status) {
                  executionStatusMutation.mutate({
                    id: lineItemDetails.id,
                    toUpdate: {
                      executionStatus: status as ExecutionStatus,
                    },
                  });
                }
              }}
              selected={lineItemDetails.executionStatus || null}
            />
          </InfoLabel>
        </div>
      </div>
    </RightPanelSection>
  );
};
