import { Courier } from "api/shipping/models";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";
import { shippingActions } from "api/shipping/actions";
import { ShippingService } from "constants/shippingService";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { TextField } from "components/miloDesignSystem/atoms/textField";

interface Props {
  courier: Courier;
}

export const GeneralInfoSection = ({ courier }: Props) => {
  return (
    <>
      <RightPanelSection padding="px-3 pt-0 pb-0">
        <div className="d-flex align-items-start justify-content-between gap-2 ">
          <div className="flex-1">
            <InfoLabel title="kurier">
              <Typography fontSize="14" fontWeight="700">
                {courier.provider === ShippingService["NOT CONNECTED"]
                  ? EMPTY_VALUE
                  : courier.provider}
              </Typography>
            </InfoLabel>
            <InfoLabel title="nazwa">
              <TextField.Async
                mutationHook={shippingActions.useCourierPatchMutation}
                placeholder="wpisz nazwę"
                transformQueryData={name => ({
                  id: courier.id,
                  toUpdate: {
                    name,
                  },
                })}
                value={courier.name}
              />
            </InfoLabel>
            <InfoLabel title="nazwa użytkownika">
              <TextField.Async
                mutationHook={shippingActions.useCourierPatchMutation}
                placeholder="wpisz nazwę użytkownika"
                transformQueryData={username => ({
                  id: courier.id,
                  toUpdate: {
                    username,
                  },
                })}
                value={courier.username}
              />
            </InfoLabel>
            <InfoLabel title="hasło użytkownika">
              <TextField.Async
                mutationHook={shippingActions.useCourierPatchMutation}
                placeholder="wpisz hasło użytkownika"
                transformQueryData={password => ({
                  id: courier.id,
                  toUpdate: {
                    password,
                  },
                })}
                value={courier.password}
              />
            </InfoLabel>
            <InfoLabel title="Czy zamawianie kuriera jest wymagane">
              <Checkbox.Async
                checked={courier.isCalledPickupRequired}
                label=""
                mutationHook={shippingActions.useCourierPatchMutation}
                size="sm"
                transformQueryData={isCalledPickupRequired => ({
                  id: courier.id,
                  toUpdate: { isCalledPickupRequired },
                })}
              />
            </InfoLabel>
          </div>
        </div>
      </RightPanelSection>
    </>
  );
};
