import styles from "./DevMode.module.css";
import cx from "classnames";
import { Button } from "components/common/buttonLegacy";
import { useUtilsModeDetails } from "api/other/hooks";
import { FormCheckbox, FormInput, Modal, RadioLabels } from "components/utils";
import { useToggle } from "hooks";
import { PopupMenu } from "components/common/popupMenu";
import { useMutation } from "hooks/useMutation";
import { postDevelopmentOrders, postDevelopmentDrawOrdersCreatedDate } from "api/orders/calls";
import { OrderPaymentType, PostDevelopmentOrdersSchema } from "api/orders/models";
import { Formik } from "formik";
import { RangeInput } from "../rangeInput";
import { availablePaymentTypesDict, Channels, CHANNELS, CURRENCY, CURRENCY_TYPE } from "CONSTANTS";
import produce from "immer";
import { useSelector } from "hooks";

const initialState: PostDevelopmentOrdersSchema = {
  numberOfOrders: 10,
  items: {
    minRangeNumber: 1,
    maxRangeNumber: 9,
    minPrice: "2.99",
    maxPrice: "578.99",
    minQuantity: 1,
    maxQuantity: 10,
    includeSets: true,
  },
  marketplaces: {
    minNumberOfOrders: 1,
    maxNumberOfOrders: 5,
    channels: ["allegro", "amazon", "ebay", "presta", "b2b", "arena"],
  },
  customers: {
    minNumberOfOrders: 1,
    maxNumberOfOrders: 5,
    includeDropshippingCustomers: true,
  },
  deliveries: {
    minPrice: "159.99",
    maxPrice: "319.99",
  },
  payments: {
    maximumDiscount: "20.89",
    types: ["CASH_ON_DELIVERY", "DEBIT_CARD", "INSTALMENT"],
    selectedCurrencies: ["PLN", "EUR", "CZK", "HUF"],
  },
  whichOfficeEmployees: [
    "daniel.m",
    "pawel.j",
    "tomasz.n",
    "wojtek.j",
    "szymon.sz",
    "sylwia.d",
    "michal.cz",
    "wiktor.b",
    "weronika.p",
  ],
};

export const DevModeBar = () => {
  const { data } = useUtilsModeDetails("");
  const user = useSelector(state => state.auth.user);
  const developmentOrdersModal = useToggle();
  const postDevelopmentOrdersMutation = useMutation(postDevelopmentOrders, {
    onSuccess: () => {
      developmentOrdersModal.close();
    },
  });

  if (!data || !user || (data.mode === "PRODUCTION" && user.type !== "developer")) return null;

  return (
    <div className={styles.devModeWrapper}>
      <div className="d-flex align-items-center justify-content-end">
        <div className="d-flex align-items-center mr-3">
          <div
            className={cx(styles.diode, {
              [styles.diodeRed]: data.mode === "DEVELOPMENT" || data.mode === "PRODUCTION",
              [styles.diodeYellow]: data.mode === "TEST",
            })}
          ></div>
          <div>
            <div className={styles.mode}>{data.mode}</div> mode
          </div>
        </div>
        {(data.mode === "DEVELOPMENT" || user.type === "developer") && (
          <>
            {data.debug ? (
              <div className="d-flex align-items-center mr-1">
                <div className={cx(styles.diode, styles.diodeGreen)} />
                <div>Debug</div>
              </div>
            ) : (
              <div className="d-flex align-items-center mr-1">
                <div className={styles.diode} />
                <div>Debug</div>
              </div>
            )}
            <div>
              <PostDevelopmentOrdersModal
                isOpen={developmentOrdersModal.isOpen}
                close={developmentOrdersModal.close}
                onSubmit={values => postDevelopmentOrdersMutation.mutate(values)}
              />
            </div>
            <span className={styles.verticalDivider}></span>
            <div className={cx(styles.instance, "d-flex align-items-center")}>
              <span className="me-1">Instancja</span> <strong>{data.instance}</strong>
            </div>
            <div className="ml-1">
              <PopupMenu dataFor="external-options">
                <div className="p-3">
                  <div className={styles.label}>Zamówienia</div>
                  <Button
                    size="small"
                    kind="primary"
                    className="mb-3"
                    onClick={() => postDevelopmentOrdersMutation.mutate(initialState)}
                    disabled={postDevelopmentOrdersMutation.isLoading}
                  >
                    Utwórz domyślne testowe zamówienia
                  </Button>
                  <Button
                    size="small"
                    kind="primary"
                    className="mb-3"
                    onClick={developmentOrdersModal.open}
                  >
                    Utwórz testowe zamówienia
                  </Button>
                  <Button
                    size="small"
                    kind="primary"
                    onClick={postDevelopmentDrawOrdersCreatedDate}
                  >
                    Losuj czas stworzenia zamówień
                  </Button>
                </div>
              </PopupMenu>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const PostDevelopmentOrdersModal = ({
  close,
  isOpen,
  onSubmit,
}: {
  isOpen: boolean;
  close: () => void;
  onSubmit: (data: PostDevelopmentOrdersSchema) => void;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      close={close}
      size="auto"
      overrides={{ container: { className: styles.modalSmall } }}
    >
      <Formik initialValues={initialState} onSubmit={onSubmit}>
        {({ handleSubmit, values, setFieldValue, isValid, isSubmitting, setValues }) => (
          <form
            onSubmit={handleSubmit}
            className={cx({ "was-validated": !isValid }, styles.sections)}
          >
            <div className={styles.item}>
              <div className={styles.label}>Ilość zamówień</div>
              <FormInput
                name="numberOfOrders"
                label="Ilość zamówień"
                type="number"
                overwrites={{ wrapper: { className: "mb-0" } }}
              />
            </div>
            <div className={styles.title}>Produkty</div>
            <div className={styles.item}>
              <div className={styles.label}>Ilość produktów w zamówieniu</div>
              <RangeInput
                values={[
                  Number(values.items.minRangeNumber) || 0,
                  Number(values.items.maxRangeNumber) || Infinity,
                ]}
                onChange={(range: number[]) => {
                  let [min, max] = range;

                  setFieldValue("items.minRangeNumber", min);
                  setFieldValue("items.maxRangeNumber", max);
                }}
                domain={[1, 50]}
                unit="szt"
              />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Cena</div>
              <RangeInput
                values={[
                  Number(values.items.minPrice) || 0,
                  Number(values.items.maxPrice) || Infinity,
                ]}
                onChange={(range: number[]) => {
                  let [min, max] = range;

                  setFieldValue("items.minPrice", min);
                  setFieldValue("items.maxPrice", max);
                }}
                domain={[1, 10000]}
                unit="pln"
              />
            </div>
            <div className={styles.item}>
              <div className={styles.label}>Quantity produktu</div>
              <RangeInput
                values={[
                  Number(values.items.minQuantity) || 0,
                  Number(values.items.maxQuantity) || Infinity,
                ]}
                onChange={(range: number[]) => {
                  let [min, max] = range;

                  setFieldValue("items.minQuantity", min);
                  setFieldValue("items.maxQuantity", max);
                }}
                domain={[1, 50]}
                unit="szt"
              />
            </div>

            <div className={styles.item}>
              <FormCheckbox name="items.includeSets" label="zawiera zestawy" />
            </div>
            <div className={styles.title}>Konta sprzedażowe</div>
            <div className={styles.item}>
              <div className={styles.label}>ilość w zamówieniach</div>
              <RangeInput
                values={[
                  Number(values.marketplaces.minNumberOfOrders) || 0,
                  Number(values.marketplaces.maxNumberOfOrders) || Infinity,
                ]}
                onChange={(range: number[]) => {
                  let [min, max] = range;

                  setFieldValue("marketplaces.minNumberOfOrders", min);
                  setFieldValue("marketplaces.maxNumberOfOrders", max);
                }}
                domain={[1, 50]}
                unit="szt"
              />
            </div>

            <div className={styles.item}>
              <div className={styles.label}>konta sprzedażowe</div>
              <RadioLabels
                label=""
                name="marketplace.channels"
                items={CHANNELS}
                onChange={({ value }) => {
                  setValues(
                    produce(values, draft => {
                      if (values.marketplaces.channels.includes(value as Channels)) {
                        draft.marketplaces.channels = draft.marketplaces.channels.filter(
                          el => el !== value,
                        );
                      } else {
                        draft.marketplaces.channels.push(value as Channels);
                      }
                    }),
                  );
                }}
                multiple={true}
                values={values.marketplaces.channels}
              />
            </div>

            <div className={styles.title}>Kontrahent</div>
            <div className={styles.item}>
              <div className={styles.label}>ilość w zamówieniach</div>
              <RangeInput
                values={[
                  Number(values.customers.minNumberOfOrders) || 0,
                  Number(values.customers.maxNumberOfOrders) || Infinity,
                ]}
                onChange={(range: number[]) => {
                  let [min, max] = range;

                  setFieldValue("customers.minNumberOfOrders", min);
                  setFieldValue("customers.maxNumberOfOrders", max);
                }}
                domain={[1, 50]}
                unit="szt"
              />
            </div>

            <div className={styles.item}>
              <FormCheckbox
                name="customers.includeDropshippingCustomers"
                label="kontrahent z dropshipping"
              />
            </div>

            <div className={styles.title}>Transport</div>
            <div className={styles.item}>
              <div className={styles.label}>Cena</div>
              <RangeInput
                values={[
                  Number(values.deliveries.minPrice) || 0,
                  Number(values.deliveries.maxPrice) || Infinity,
                ]}
                onChange={(range: number[]) => {
                  let [min, max] = range;

                  setFieldValue("deliveries.minPrice", min);
                  setFieldValue("deliveries.maxPrice", max);
                }}
                domain={[1, 1000]}
                unit="pln"
              />
            </div>
            <div className={styles.title}>Płatności</div>
            <div className={styles.item}>
              <FormInput
                name="payments.maximumDiscount"
                label="Maksymalna zniżka"
                type="number"
                overwrites={{ wrapper: { className: "mb-0" } }}
              />
            </div>

            <div className={styles.item}>
              <div className={styles.label}>Konto sprzedażowe</div>
              <RadioLabels
                label=""
                name="payments.types"
                items={Object.entries(availablePaymentTypesDict).map(([key, value]) => ({
                  id: key,
                  name: value,
                }))}
                onChange={({ value }) => {
                  setValues(
                    produce(values, draft => {
                      if (values.payments.types.includes(value as OrderPaymentType)) {
                        draft.payments.types = draft.payments.types.filter(el => el !== value);
                      } else {
                        draft.payments.types.push(value as OrderPaymentType);
                      }
                    }),
                  );
                }}
                multiple
                values={values.payments.types}
              />
            </div>

            <div className={styles.item}>
              <div className={styles.label}>Waluta</div>
              <RadioLabels
                label=""
                name="payments.selectedCurrencies"
                items={CURRENCY}
                onChange={({ value }) => {
                  setValues(
                    produce(values, draft => {
                      if (values.payments.selectedCurrencies.includes(value as CURRENCY_TYPE)) {
                        draft.payments.selectedCurrencies = draft.payments.selectedCurrencies.filter(
                          el => el !== value,
                        );
                      } else {
                        draft.payments.selectedCurrencies.push(value as CURRENCY_TYPE);
                      }
                    }),
                  );
                }}
                multiple
                values={values.payments.selectedCurrencies}
              />
            </div>

            <div className={styles.title}>Pracownik</div>
            <RadioLabels
              label=""
              name="whichOfficeEmployees"
              items={initialState.whichOfficeEmployees.map(el => ({ id: el, name: el }))}
              onChange={({ value }) => {
                setValues(
                  produce(values, draft => {
                    if (values.whichOfficeEmployees.includes(value as CURRENCY_TYPE)) {
                      draft.whichOfficeEmployees = draft.whichOfficeEmployees.filter(
                        el => el !== value,
                      );
                    } else {
                      draft.whichOfficeEmployees.push(value as CURRENCY_TYPE);
                    }
                  }),
                );
              }}
              multiple
              values={values.whichOfficeEmployees}
            />
            <Button kind="primary" type="submit" disabled={isSubmitting} className="mt-4">
              Create
            </Button>
          </form>
        )}
      </Formik>
    </Modal>
  );
};
