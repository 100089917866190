import { TradingDocument } from "api/trading-documents/models";
import styles from "../../CreateDraftDocument.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ConfirmTradingDocument } from "pages/tradingDocuments/shared/components/rightPanel/ConfirmTradingDocument";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  draftInvoice: TradingDocument;
}

export const HeaderSection = ({ draftInvoice }: Props) => {
  const handleSalesInvoicePdfDownload = tradingDocumentsActions.useInvoicePdfDownload();

  return (
    <div className={styles.contentHeader}>
      <div className="d-flex align-items-center gap-2 w-100">
        <Typography fontSize="20" fontWeight="700">
          {draftInvoice.signature}
        </Typography>
        <ConfirmTradingDocument tradingDocument={draftInvoice} />
        <Tooltip title="Pobierz PDF z danymi faktury sprzedażowej">
          <IconButton
            icon={MdiDownloadPdf}
            onClick={() => handleSalesInvoicePdfDownload(draftInvoice)}
            variant="transparent"
          />
        </Tooltip>
      </div>
    </div>
  );
};
