import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import factory from "assets/images/moduleNavigationIcons/factory.svg";
import { ManufacturersRouting } from "routes/manufacturingNew/ManufacturersRouting";

export const manufacturers: ModuleLink = {
  url: "manufacturers",
  label: "Dostawcy",
  icon: factory,
  routing: ManufacturersRouting,
};
