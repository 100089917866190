export type ListConfig = {
  grid: string;
  labels: string[];
};

export const salesInvoiceListConfig: ListConfig = {
  grid:
    "20px minmax(130px, 2fr) 160px minmax(100px, 1fr) 200px 50px 90px 90px 120px 110px 100px 70px 90px 70px 70px minmax(100px, 1fr)",
  labels: [
    "",
    "nabywca",
    "sygnatura",
    "kwota",
    "f.zaliczkowe",
    "kraj",
    "wystawiono",
    "termin",
    "zamówienie",
    "status dostarczenia",
    "data dostarczenia",
    "płatność",
    "należne",
    "wysyłka",
    "przypisano",
    "sprzedawca",
  ],
};

export const advanceInvoiceListConfig: ListConfig = {
  grid:
    "20px minmax(130px, 2fr) 160px minmax(100px, 1fr) 100px 50px 90px 90px 120px 100px 100px 70px 90px 70px 70px minmax(100px, 1fr)",
  labels: [
    "",
    "nabywca",
    "sygnatura",
    "kwota",
    "f.sprzedażowe",
    "kraj",
    "wystawiono",
    "termin",
    "zamówienie",
    "status dostarczenia",
    "data dostarczenia",
    "płatność",
    "należne",
    "wysyłka",
    "przypisano",
    "sprzedawca",
  ],
};

export const proformaInvoiceListConfig: ListConfig = {
  grid:
    "20px minmax(130px, 2fr) 160px minmax(100px, 1fr) 50px 90px 90px 120px 70px 90px 70px 70px minmax(100px, 1fr)",
  labels: [
    "",
    "nabywca",
    "sygnatura",
    "kwota",
    "kraj",
    "wystawiono",
    "termin",
    "zamówienie",
    "płatność",
    "należne",
    "wysyłka",
    "przypisano",
    "sprzedawca",
  ],
};

export const receiptListConfig: ListConfig = {
  grid: "20px 1fr 90px 110px 100px 100px 100px 80px 100px 80px 1fr",
  labels: [
    "",
    "nr paragonu",
    "status",
    "zamówienia",
    "kwota",
    "data sprzedaży",
    "data wystawienia",
    "zlecił wydruk",
    "data wydruku",
    "przypisano",
    "sprzedawca",
  ],
};

export const purchaseInvoiceListConfig: ListConfig = {
  grid: "20px 2fr 1fr 20px 1fr 1fr 100px 100px 1fr 70px 1fr",
  labels: [
    "",
    "sprzedawca",
    "sygnatura",
    "",
    "kwota",
    "wystawiono",
    "termin",
    "płatność",
    "należne",
    "przypisano",
    "nabywca",
  ],
};

export const correctionInvoiceListConfig: ListConfig = {
  grid:
    "20px minmax(130px, 2fr) 140px 110px minmax(100px, 1fr) 50px 110px 60px 120px 75px 70px 70px minmax(250px, 1fr)",
  labels: [
    "",
    "nabywca",
    "sygnatura",
    "nr zamówień",
    "przyczyna",
    "kraj",
    "faktura",
    "wystawiono",
    "termin",
    "podpis",
    "wysyłka",
    "przypisano",
    "sprzedawca",
  ],
};

export const myPurchaseInvoicesToReviewListConfig: ListConfig = {
  grid: "0.3fr 1.2fr 0.7fr 0.7fr 0.5fr 1.2fr 0.7fr 1.3fr",
  labels: ["status", "sprzedawca", "sygnatura", "kwota", "wystawiono", "recenzenci", "nabywca", ""],
};

export const callCenterRoutesListConfig: ListConfig = {
  grid: "40px 200px 1fr 120px 90px 90px 100px 150px 40px 120px",
  labels: [
    "",
    "sygnatura",
    "nazwa",
    "wyjazd",
    "status",
    "opóźnienie",
    "samochód",
    "kierowca",
    "",
    "odp.",
  ],
};

export const callCenterRouteOrdersListConfig: ListConfig = {
  grid: "20px 170px 120px 190px 80px minmax(100px, 1fr) 80px 120px 220px 100px 120px 100px 120px",
  labels: [
    "#",
    "nr zamówienia",
    "wymagany tel.",
    "telefon",
    "nr zewn.",
    "klient",
    "potwierdzić do",
    "kierowca",
    "spodziewana dostawa",
    "wiadomość",
    "potwierdzenie",
    "odrzucenie",
    "odp.",
  ],
};

export const callCenterSingleRouteOrdersListConfig: ListConfig = {
  grid:
    "20px 16px 180px 120px 70px 120px 80px minmax(100px, 1fr) 80px 120px 220px 100px 105px 85px 120px",
  labels: [
    "#",
    "",
    "nr zamówienia",
    "wymagany tel.",
    "telefon",
    "",
    "nr zewn.",
    "klient",
    "potwierdzić do",
    "kierowca",
    "spodziewana dostawa",
    "wiadomość",
    "potwierdzenie",
    "odrzucenie",
    "odp.",
  ],
};

export const logisticsRoutesListConfig: ListConfig = {
  grid: "20px 16px 75px 200px 1.5fr 100px 1fr 60px 140px 140px 80px",
  labels: [
    "",
    "",
    "priorytet",
    "sygnatura",
    "nazwa",
    "samochód",
    "kierowca",
    "status",
    "załadunek",
    "wyjazd",
    "tracking",
  ],
};

export const logisticsRouteTradingDocumentsListConfig: ListConfig = {
  grid: "20px 2fr 160px 1fr 50px 90px 90px 120px 70px 90px 70px 70px 1fr",
  labels: [
    "",
    "nabywca",
    "sygnatura",
    "kwota",
    "kraj",
    "data",
    "termin",
    "zamówienie",
    "płatność",
    "należne",
    "wysyłka",
    "przypisano",
    "sprzedawca",
  ],
};

export const callCenterOrdersListConfig: ListConfig = {
  grid:
    "16px 110px 120px 100px 90px 80px minmax(100px, 1fr) 80px 120px 220px 85px 110px 100px 120px",
  labels: [
    "",
    "nr zamówienia",
    "wymagany tel.",
    "telefon",
    "",
    "nr zewn.",
    "klient",
    "potwierdzić do",
    "kierowca",
    "spodziewana dostawa",
    "wiadomość",
    "status potwierdzenia",
    "trasa/grupa",
    "odp.",
  ],
};

export const manufacturingPlansListConfig: ListConfig = {
  grid: "21px minmax(110px, 1fr) 100px 80px 90px 90px 70px 150px 80px 26px 80px 90px 90px",
  labels: [
    "",
    "sygnatura",
    "etap",
    "status",
    "początek",
    "koniec",
    "tydzień roku",
    "kraj wys.",
    "wypełnienie",
    "",
    "utworzył",
    "utworzono",
    "ostatnia edycja",
  ],
};

export const manufacturingSchemasListConfig: ListConfig = {
  grid:
    "minmax(110px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) 80px 70px minmax(200px, 3fr) 90px",
  labels: [
    "sygnatura",
    "nazwa",
    "opis",
    "tworzony przez",
    "liczba etapów",
    "etapy",
    "ostatnia edycja",
  ],
};

export const manufacturingEmployeesListConfig: ListConfig = {
  grid: "20px minmax(156px, 250px) minmax(100px, 150px) 100px 130px 130px 1fr 43px",
  labels: [
    "#",
    "imię nazwisko ",
    "stanowisko",
    "gdzie zatrudniony",
    "saldo",
    "ostatnia płatność [kwota]",
    "ostatnia płatność [data]",
    "etykieta",
  ],
};

export const wmsCargoUnloadsListConfig: ListConfig = {
  grid: "minmax(120px, 1fr) minmax(120px, 1fr) minmax(120px, 1fr) 40px 90px 120px 90px 90px 30px",
  labels: [
    "sygnatura",
    "producenci",
    "trasa",
    "rampa",
    "przyjazd",
    "start",
    "zakończenie",
    "czas trwania",
    "",
  ],
};
