import { MyPurchaseInvoiceToReview } from "api/trading-documents/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import { myPurchaseInvoicesToReviewListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import cx from "classnames";
import styles from "../../../tradingDocumentsList/TradingDocumentsList.module.css";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { dateFns } from "utilities";
import checkIcon from "assets/images/check_small.svg";
import { StateLabel } from "components/common/stateLabel";
import { Button } from "components/common";
import { useHistory } from "react-router-dom";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  myInvoice: MyPurchaseInvoiceToReview;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => void;
  togglePanel: (id: string) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  myInvoice,
  selectMutlipleItems,
  togglePanel,
}: Props) => {
  const history = useHistory();

  return (
    <ModuleRow
      className={cx({
        active: isActive(myInvoice.id),
        highlighted:
          isHighlighted(myInvoice.id) || highlightedRows.some(row => row.id === myInvoice.id),
      })}
      gridStyle={myPurchaseInvoicesToReviewListConfig.grid}
      onMouseDown={event =>
        selectMutlipleItems(event, myInvoice.id, index, () => togglePanel(myInvoice.id))
      }
    >
      <div className="d-flex align-items-center">
        {myInvoice.reviewStatus === "OPEN" ? (
          <StateLabel kind="green">
            {tradingDocumentConstants.reviewStatusDict[myInvoice.reviewStatus]}
          </StateLabel>
        ) : (
          <StateLabel kind="transparentViolet">
            {tradingDocumentConstants.reviewStatusDict[myInvoice.reviewStatus]}
          </StateLabel>
        )}
      </div>
      <div className="d-flex align-items-center gap-1">
        <div className={cx(styles.client, "fw-700")}>
          {myInvoice.sellerCompanyName ? (
            myInvoice.sellerCompanyName
          ) : myInvoice.sellerFirstName && myInvoice.sellerLastName ? (
            <>
              {myInvoice.sellerFirstName} {myInvoice.sellerLastName}
            </>
          ) : (
            "---"
          )}
        </div>
      </div>
      <div className={styles.signature}>{myInvoice.signature ? myInvoice.signature : "---"}</div>
      <div className={cx(styles.amountToPay, "d-flex align-items-center")}>
        <FinanceAmountFormat value={myInvoice.totalWithTax} />
      </div>
      <div className={styles.date}>
        {myInvoice.invoiceIssueDate
          ? dateFns.format(new Date(myInvoice.invoiceIssueDate), "dd.MM.yyyy")
          : "---"}
      </div>
      <div>
        <div className={styles.avatarBox}>
          {myInvoice.reviewers.slice(0, 7).map(reviewer => {
            if (reviewer.user.avatar) {
              return (
                <div
                  key={reviewer.id}
                  className={cx(styles.listAvatar, styles.avatarSmall, "mr-n1")}
                >
                  <img alt="" src={reviewer.user.avatar} />
                </div>
              );
            } else {
              return (
                <div
                  key={reviewer.id}
                  className={cx(
                    styles.listAvatar,
                    styles.avatarSmall,
                    styles.initialsAvatar,
                    "mr-n1",
                  )}
                >
                  {reviewer.user.firstName.charAt(0).toLocaleUpperCase()}
                  {reviewer.user.lastName.charAt(0).toLocaleUpperCase()}
                </div>
              );
            }
          })}
          {myInvoice.reviewers.length > 7 && (
            <div className={styles.morePeopleCounter}>+{myInvoice.reviewers.length - 7}</div>
          )}
        </div>
      </div>
      <div className={styles.subject}>
        {myInvoice.recipientCompanyName ? (
          myInvoice.recipientCompanyName
        ) : myInvoice.recipientFirstName && myInvoice.recipientLastName ? (
          <>
            {myInvoice.recipientFirstName} {myInvoice.recipientLastName}
          </>
        ) : (
          "---"
        )}
      </div>
      <div className={styles.confirmInvoiceBtn}>
        <Button
          className={styles.confirmationBtn}
          kind="create"
          onClick={e => {
            e.stopPropagation();
            history.push(`/finances/import-invoices/check?id=${myInvoice.id}`, {
              fromMyInvoices: `${history.location.pathname}${history.location.search}`,
            });
          }}
          title="Zatwierdź fakturę"
        >
          <div className="btnBase btnBaseSmall">
            <img alt="Gotowe" src={checkIcon} style={{ height: "16px", width: "16px" }} />
            Przejdź do zatwierdzania
          </div>
        </Button>
      </div>
    </ModuleRow>
  );
};
