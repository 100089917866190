import { createPaginatedKey } from "api/keys";
import { UUID } from "api/types";

export const orderKeys = {
  itemsWithSingleQuantities: createPaginatedKey("itemsWithSingleQuantities"),
  singleItemQuantities: createPaginatedKey("singleItemQuantities"),
  singleItemQuantitiesDetails: createPaginatedKey("singleItemQuantitiesDetails"),
  ordersFreeSingleItemQuantities: createPaginatedKey("ordersFreeSingleItemQuantities"),
  manufacturingOrders: createPaginatedKey("manufacturingOrders"),
  singleItemQuantitiesForRoute: createPaginatedKey("singleItemQuantitiesForRoute"),
  orderWarehouseLocations: createPaginatedKey("orderWarehouseLocations"),
  singleItemQuantityWarehouseLocations: createPaginatedKey("singleItemQuantityWarehouseLocations"),
  orderFreeSingleItemQuantitiesDetails: (id: UUID) => ["orderFreeSingleItemQuantitiesDetails", id],
  ordersForInvoicing: createPaginatedKey("ordersForInvoicing"),
  orderManufacturingSingleItemQuantities: createPaginatedKey(
    "orderManufacturingSingleItemQuantities",
  ),
  ordersGroupByInfo: (search: string) => ["ordersGroupByInfo", search],
  ordersSalesAccountInfo: (search: string) => ["ordersSalesAccountInfo", search],
  manufacturing: {
    orderDetails: (orderId: number) => ["manufacturingOrderDetails", String(orderId)],
  },
};
