import { CURRENCY_TYPE } from "CONSTANTS";
import { FabricAttributeKind, StandardAttributeKind } from "api/attributes/models";
import { Customer } from "api/customers/models";
import { ManufacturingSchema } from "api/manufacturingNew/models";

export enum ProductKind {
  BOX = "box",
  OTHER = "other",
  SERVICE = "service",
  SET = "set",
  UPHOLSTERY = "upholstery",
}

export interface ProductAttributeStandard {
  id: number;
  name: string;
  description: string;
  kind: StandardAttributeKind;
  values: ProductValue[];
}

export interface ProductAttributeFabric {
  id: number;
  name: string;
  description: string;
  kind: FabricAttributeKind;
  categories: {
    name: string;
    values: ProductValue[];
  }[];
}

export interface ProductValue {
  id: number;
  isAssignableToIndex: boolean;
  picture: string | null;
  name: string;
}

export interface GeneratePackagesPayload {
  indexes?: number[];
  products?: number[];
}

export type ProductAttribute = ProductAttributeStandard | ProductAttributeFabric;

export interface Category {
  id: number;
  name: string;
  picture: string | null;
}

export interface RightPanelProductAttribute {
  id: number;
  showOnLabel: boolean;
  position: number;
  attribute: RightPanelAttributeStandard | RightPanelAttributeFabric;
  product: {
    id: number;
    kind: "box" | "upholstery" | "other";
    name: string;
  };
}

export interface RightPanelAttributeStandard {
  id: number;
  position: number;
  name: string;
  description: string;
  kind: "PICTURE" | "NUMBER" | "TEXT";
  productAttributeValues: ProductAttributeValue[];
}

export interface RightPanelAttributeFabric {
  id: number;
  position: number;
  name: string;
  description: string;
  kind: FabricAttributeKind;
  categories: {
    id: number;
    name: string;
    productAttributeValues: ProductAttributeValue[];
  }[];
}

export interface ProductAttributeValue {
  id: number;
  value: {
    name: string;
    id: number;
    picture: string | null;
    position: number;
    category: string;
    isDeleted: boolean;
  };
}

export interface LogCategory {
  id: number;
  code: string;
}

export type ProductComponent = Product & {
  productId: number;
  amount: number;
  currency: CURRENCY_TYPE;
  name: string;
  picture: string;
  weight: number;
  quantity: number;
  index: number | null;
};

export interface Product {
  id: number;
  ean: string;
  name: string;
  manufacturerName: string;
  externalCode: string;
  kind: ProductKind;
  picture: string;
  weight: number;
  height: number;
  width: number;
  depth: number;
  isDeleted: boolean;
  customer: Pick<Customer, "id" | "name"> | null;
  isSelfProduced: boolean;
  created: string;
  isInProductSet: boolean;
  lastModified: string;
  attributes: ProductAttribute[];
  availability: number;
  indexes: { [key: string]: number };
  prices: { [key: number]: { amount: number; currency: string } };
  manufacturer: null | { id: number; name: string };
  category: null | { id: number; name: string };
  setMetaData: { quantity: number; componentId: number; index: number | null } | null;
  components: ProductComponent[];
  indexData: {
    [key: string]: {
      height: number;
      amount: number;
      weight: number;
      volume: number;
      packages: {
        name: string;
        id: number;
        package: number;
        internalId: string;
        weight: string;
        height: string;
        width: string;
        depth: string;
      }[];
      packagesVolume: number;
      packagesWeight: number;
      manufacturingSchema: Pick<ManufacturingSchema, "id" | "name" | "signature"> | null;
      width: number;
      depth: number;
      deliveryAmount: number;
      itemsInPackage: number;
      isDeleted: boolean;
    };
  };
  combinedNomenclatureCode: string;
}

export interface ProductLight {
  id: number;
  name: string;
  picture: string;
  isInProductSet: boolean;
  kind: Product["kind"];
}

export interface ProductLightWithDeleted {
  id: number;
  name: string;
  picture: string;
  isInProductSet: boolean;
  customer: Pick<Customer, "id" | "name"> | null;
  kind: Product["kind"];
}

export interface PostClientProduct {
  name: string;
  manufacturerName: string;
  manufacturingName: string;
  kind: "" | "box" | "upholstery" | "other" | "set";
  picture: File | null | string;
  height: string | number;
  width: string | number;
  weight: string | number;
  depth: string | number;
  values: string;
  packages: {
    name: string | number;
    height: string | number;
    width: string | number;
    weight: string | number;
    depth: string | number;
  }[];
}

export interface PostProduct {
  name: string;
  manufacturerName: string;
  kind: "" | ProductKind;
  picture: File | null | string;
  height: string | number;
  width: string | number;
  weight: string | number;
  depth: string | number;
  isSelfProduced: boolean;
  values: number[];
  manufacturer: null | { id: number; name: string };
  category: null | { id: number; name: string };
}

export interface ProductEntityListItem {
  id: number;
  name: string;
  kind: "set" | "box" | "frame" | "upholstery" | "other";
  picture: string | null;
  weight: string;
  height: string;
  width: string;
  depth: string;
  combinedNomenclatureCode: string;
  availability: number;
  isSelfProduced: boolean;
  values: unknown;
  created: string;
  lastModified: string;
  indexes: Record<string, number>;
  indexData: Record<
    string,
    {
      height: number;
      volume: null | number | string;
      depth: number;
      width: number;
      weight: number;
      packages: {
        id: number;
        name: string;
        width: number;
        depth: number;
        height: number;
        weight: number;
        internalId: string;
      }[];
    }
  >;
  prices: Record<
    string,
    {
      amount: number;
    }
  >;
  manufacturerName: string;
  manufacturer: {
    id: number;
    name: string;
  } | null;
  category: {
    id: number;
    name: string;
    picture: string | null;
  } | null;
  attributes: ProductAttribute[];
}

export interface ProductEntity {
  commonAttributes: ProductAttribute[];
  productSetCode: string;
  ean: string;
  id: number;
  name: string;
  products: Product[];
}

export interface PostPackage {
  depth: string;
  height: string;
  indexId: number;
  name: string;
  weight: string;
  width: string;
}

export interface PostPackagePreview {
  depth: string;
  height: string;
  id: number;
  indexes: number[];
  internalId: string;
  name: string;
  weight: string;
  width: string;
}

export interface ProductToFetch {
  combinedNomenclatureCode: string;
  created: string;
  depth: string;
  height: string;
  id: number;
  isInProductSet: boolean;
  isSelfProduced: boolean;
  kind: ProductKind;
  lastModified: string | null;
  name: any;
  picture: string | null;
  weight: string;
  width: string;
}

export type CnCode = {
  id: string;
  name: string;
};

export const cnCodes: CnCode[] = [
  { id: "94014000", name: "94014000: siedzenie" },
  { id: "94014900", name: "94014900: siedzenie tapicerowane z funkcją spania" },
  { id: "94016100", name: "94016100: siedzenie (fotel, podnóżek)" },
  { id: "94035000", name: "94035000: meble drewniane" },
  { id: "94036010", name: "94036010: pozostałe meble drewniane" },
  { id: "94041000", name: "94041000: materace" },
  { id: "94042910", name: "94042910: materace ze sprężynami" },
  { id: "94042990", name: "94042990: pozostałe materace" },
  { id: "94054099", name: "94054099: pozostałe lampy i oprawy oświetleniowe" },
];
