import { UUID } from "api/types";
import { CountryCode } from "CONSTANTS";

export type DefaultPaymentMethod = "CASH" | "ONLINE";

export type SellerKind = "EXTERNAL" | "INTERNAL";

export const defaultPaymentMethods: { value: DefaultPaymentMethod; name: string }[] = [
  { value: "CASH", name: "Gotówka" },
  { value: "ONLINE", name: "Przelew" },
];

export const sellerKindOptions: { id: SellerKind; name: string }[] = [
  { id: "EXTERNAL", name: "obcy" },
  { id: "INTERNAL", name: "własny" },
];

export const countryCodes: { code: CountryCode | string; name: string }[] = [
  { code: "DE", name: "Niemcy" },
  { code: "PL", name: "Polska" },
  { code: "GB", name: "Wielka Brytania" },
  { code: "CZ", name: "Czechy" },
  { code: "SK", name: "Słowacja" },
  { code: "FR", name: "Francja" },
  { code: "HU", name: "Węgry" },
  { code: "AT", name: "Austria" },
  { code: "IT", name: "Włochy" },
  { code: "LV", name: "Łotwa" },
  { code: "SI", name: "Słowenia" },
  { code: "LT", name: "Litwa" },
  { code: "RO", name: "Rumunia" },
  { code: "LU", name: "Luksemburg" },
  { code: "IE", name: "Irlandia" },
  { code: "BE", name: "Belgia" },
  { code: "NL", name: "Holandia" },
  { code: "ES", name: "Hiszpania" },
  { code: "EE", name: "Estonia" },
];

export enum EntityDistrict {
  EUROPEAN_UNION = "EUROPEAN_UNION",
  OTHER = "OTHER",
  POLAND = "POLAND",
}

export interface Seller {
  address: string;
  city: string;
  companyName: string;
  countryCode: CountryCode;
  currency: string;
  customTaxRate: number | null;
  defaultDistrictVatRate: {
    id: UUID;
    value: string;
    countryCode: CountryCode;
  } | null;
  defaultPaymentMethod: DefaultPaymentMethod | string;
  defaultTimeToPay: number;
  email: string;
  entityDistrict: EntityDistrict;
  eurBankAccountNumber: string;
  eurBankName: string;
  firstName: string;
  isRegisteredInOss: boolean;
  isSettlingVatUsingCashMethod: boolean;
  isUsingVatMargin: boolean;
  kind: SellerKind;
  id: UUID;
  incomeAndExpenseBalance: string;
  lastName: string;
  phone: string;
  plnBankAccountNumber: string;
  plnBankName: string;
  postCode: string;
  taxId: string;
}

export interface CreateSeller {
  address?: string;
  bankAccountNumber?: string;
  bankName?: string;
  city?: string;
  companyName: string;
  countryCode?: CountryCode | string;
  currency: string;
  defaultPaymentMethod: DefaultPaymentMethod | string;
  kind: SellerKind;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  postCode?: string;
  taxId: string;
  isRegisteredInOss?: boolean;
  isSettlingVatUsingCashMethod?: boolean;
  isUsingVatMargin?: boolean;
  entityDistrict?: EntityDistrict;
  defaultDistrictVatRate?: {
    id: UUID;
    value: number;
  } | null;
}
