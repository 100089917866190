import { Route } from "api/routes/models";
import { RightPanelSection } from "components/utils/drawer";
import copyIcon from "assets/images/copyAll.svg";
import linkIcon from "assets/images/link.svg";
import { Button } from "components/common";
import CopyToClipboardComponent from "react-copy-to-clipboard";
import { AsyncInput } from "components/utils";
import styles from "../RightPanel.module.css";
import { useRoutePatchMutation } from "api/logistics/routes/hooks";
import ReactTooltip from "react-tooltip";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";

interface Props {
  route: Route;
}

export const TitleSection = ({ route }: Props) => {
  const context = useScrollableContext();
  const nameMutation = useRoutePatchMutation();

  return (
    <RightPanelSection>
      <div className="d-flex align-items-center gap-2" ref={context.triggerElementRef}>
        <div className="fs-24">{route.signature}</div>
        <CopyToClipboardComponent text={route.signature}>
          <Button kind="transparent-black" size="square-s">
            <div
              className="btnBase btnBaseSmall btnBase16"
              data-tip
              data-for={`signature-${route.id}`}
            >
              <img alt="kopiuj" src={copyIcon} />
            </div>
          </Button>
        </CopyToClipboardComponent>
        <ReactTooltip
          className={styles.customTooltip}
          id={`signature-${route.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Skopiuj sygnaturę
        </ReactTooltip>
        <CopyToClipboardComponent text={window.location.href}>
          <Button kind="transparent-black" size="square-s">
            <div
              className="btnBase btnBaseSmall btnBase16"
              data-tip
              data-for={`page-address-${route.id}`}
            >
              <img alt="kopiuj" src={linkIcon} />
            </div>
          </Button>
        </CopyToClipboardComponent>
        <ReactTooltip
          className={styles.customTooltip}
          id={`page-address-${route.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Skopiuj link do strony
        </ReactTooltip>
        <div className="position-relative ml-4 col-6 px-0">
          <AsyncInput
            disabled={nameMutation.isLoading}
            look="common"
            label="Nazwa trasy"
            onChange={value =>
              nameMutation.mutate({
                id: route.id,
                toUpdate: { name: value },
              })
            }
            overwrites={{
              input: { className: styles.formHeight },
              floatingLabel: { className: styles.inputTextLabel },
            }}
            value={route.name}
          />
        </div>
      </div>
      <div className="pt-2" />
    </RightPanelSection>
  );
};
