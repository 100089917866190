import { TradingDocument } from "api/trading-documents/models";
import styles from "../EditTradingDocument.module.css";
import cx from "classnames";
import { useMutation } from "hooks/useMutation";
import { AsyncInput } from "components/utils";
import { dateFns, getAnyErrorKey } from "utilities";
import { DatePicker } from "components/utils/datePicker";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  tradingDocument: TradingDocument;
  updateOptions: any;
}

export const GeneralInfoSection = ({ tradingDocument, updateOptions }: Props) => {
  const signatureMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const invoiceIssueDateMutation = useMutation(updateOptions.mutationFn, updateOptions);
  const invoiceDeliveryDateMutation = useMutation(updateOptions.mutationFn, updateOptions);

  return (
    <div
      className={cx(
        styles.generalInfoSection,
        "w-100 d-flex align-items-start justify-content-between border-bottom",
      )}
    >
      <div className={cx(styles.title, "w-70 d-flex align-items-center")}>
        <div>{tradingDocumentConstants.tradingDocumentTypeDict[tradingDocument.type]}&nbsp;</div>
        {tradingDocument.type === "INVOICE" && (
          <div>{tradingDocumentConstants.invoiceTypeDict[tradingDocument.invoiceType]}&nbsp;</div>
        )}
        <div>VAT</div>
      </div>
      <div className={cx(styles.editableGeneralInfoContent, "w-30")}>
        <div className={styles.editableElement}>
          <AsyncInput
            disabled={signatureMutation.isLoading}
            error={getAnyErrorKey(signatureMutation.error)}
            inProgress={signatureMutation.isLoading}
            label="Sygnatura dokumentu"
            onChange={value =>
              signatureMutation.mutate({
                id: tradingDocument.id,
                signature: value,
              })
            }
            placeholder="Wpisz sygnaturę dokumentu"
            width="fit-content"
            value={tradingDocument.signature}
          />
        </div>
        <div className={styles.editableElement}>
          <DatePicker
            label="Data wystawienia"
            look="editable"
            onChange={date => {
              if (!date) {
                invoiceIssueDateMutation.mutate({
                  id: tradingDocument.id,
                  invoiceIssueDate: null,
                });
              } else {
                invoiceIssueDateMutation.mutate({
                  id: tradingDocument.id,
                  invoiceIssueDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                });
              }
            }}
            value={tradingDocument.invoiceIssueDate ?? ""}
          />
        </div>
        <div>
          <DatePicker
            label="Data dostawy/wykonania usługi"
            look="editable"
            onChange={date => {
              if (!date) {
                invoiceDeliveryDateMutation.mutate({
                  id: tradingDocument.id,
                  invoiceDeliveryDate: null,
                });
              } else {
                invoiceDeliveryDateMutation.mutate({
                  id: tradingDocument.id,
                  invoiceDeliveryDate: dateFns.format(new Date(date), "yyyy-MM-dd"),
                });
              }
            }}
            value={tradingDocument.invoiceDeliveryDate ?? ""}
          />
        </div>
      </div>
    </div>
  );
};
