import { useCallback, useRef, useMemo } from "react";
import * as React from "react";
import { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import { useDispatch, useSelector as useSelectorGeneric, TypedUseSelectorHook } from "react-redux";
import { dictToList, getAnyErrorKey, getPagination, tuplify } from "utilities";
import { Assign, FunctionKeys } from "utility-types";
import { Dispatch } from "redux";
import { actions } from "ducks";
import { Store } from "ducks";
import { InferResult } from "typeUtilities";
import { QueryKey, useQueryClient } from "react-query";
import { useToastr } from "components/common";
import immer from "immer";
import { Pagination, QueryFetchError } from "api/types";
import { FLAVOR } from "CONSTANTS";
import { AnyAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { useRouteMatch } from "react-router";
import { useWithDefaultNavigationCollapse } from "components/common/moduleNavigation/components/moduleNavigation/useWithDefaultNavigationCollapse";
import {
  Module,
  ModuleNavigation,
} from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { moduleConfig } from "components/common/moduleNavigation/moduleConfig";

interface BackendPagination {
  next: string | null;
  previous: string | null;
  count: number | null;
  limit: number | null;
  page_size: number;
  results: { [key: string]: unknown }[];
}

interface StateReducer<I extends {}> {
  [key: string]: (state: State & I, action: PayloadAction<unknown>) => {};
}

interface MakeReducerParams<I extends {}> {
  initialState?: I;
  stateReducer?: StateReducer<I>;
}

interface Params<I, R> {
  initialState?: I;
  stateReducer?: R;
}

export interface State {
  fetching: boolean;
  pagination: ReturnType<typeof getPagination>;
  error: null | { [key: string]: any };
  result: any;
}

type AsyncActionsResult<I extends {}, R extends {}> = [
  Assign<State, I>,
  Assign<
    {
      success: (arg?: any) => void;
      successRaw: (arg?: any) => void;
      request: (arg?: any) => void;
      failure: (arg?: any) => void;
      dispatch: React.Dispatch<AnyAction>;
    },
    { [key in FunctionKeys<R>]: (arg?: any) => void }
  >,
];

export function usePrevious<T>(value: T) {
  const ref = React.useRef<T>(value);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/**
 * redux hook extended by store type
 */
export const useSelector: TypedUseSelectorHook<Store> = useSelectorGeneric;

/**
 * hook with gathered redux actions
 */
export const useRedux = (): [Dispatch<any>, typeof actions] => {
  const dispatch = useDispatch();
  // const thunkDispatch = useDispatch<typeof store.dispatch>();
  // const bindActions: any = Object.entries(actions).reduce((reducers: Reducers, [name, entity]) => {
  //   reducers[name] = Object.entries(entity).reduce((acc: ReducerEntity, [name, action]) => {
  //     acc[name] = (payload: any) => dispatch(action(payload));
  //     return acc;
  //   }, {});
  //   return reducers;
  // }, {});
  return [dispatch, actions];
};

const checkIfPaginated = (payload: BackendPagination) => {
  if (!payload) return false;
  if (Array.isArray(payload.results) && payload.next !== undefined && payload.count !== undefined) {
    return true;
  }
  return false;
};

const initialState = {
  fetching: false,
  error: null,
  result: undefined,
  pagination: getPagination(null),
};
function makeReducer<I extends {}>({
  initialState: initial,
  stateReducer = {},
}: MakeReducerParams<I>) {
  const noopStateReducer = {
    request: () => ({}),
    success: () => ({}),
    successRaw: () => ({}),
    failure: () => ({}),
  } as StateReducer<I>;
  const { request, success, successRaw, failure } = Object.assign(
    {},
    noopStateReducer,
    stateReducer,
  );
  const standardOverwrites = {
    request,
    success,
    successRaw,
    failure,
  };
  const additionalOverwrites = Object.entries(stateReducer).reduce<StateReducer<I>>(
    (acc, [key, val]) => {
      if (["request", "success", "successRaw", "failure"].includes(key)) return acc;
      acc[key] = val;
      return acc;
    },
    {},
  );
  const reducer = createReducer({ ...initialState, ...initial } as State & I, {
    request: (state, action) => ({
      ...state,
      fetching: true,
      ...standardOverwrites.request(state as State & I, action),
    }),
    success: (state, action) => {
      const isPaginated = checkIfPaginated(action.payload);
      return {
        ...state,
        fetching: false,
        error: null,
        ...action.payload,
        result: isPaginated ? action.payload.results : action.payload,
        pagination: isPaginated ? getPagination(action.payload) : getPagination({}),
        ...standardOverwrites.success(state as State & I, action),
      };
    },
    successRaw: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: null,
        ...action.payload,
        ...standardOverwrites.successRaw(state as State & I, action),
      };
    },
    failure: (state, action) => ({
      ...state,
      fetching: false,
      error: { ...action.payload },
      ...standardOverwrites.failure(state as State & I, action),
    }),
    ...additionalOverwrites,
  });
  return reducer;
}

/**
 *
 */
export function useAsyncActions<
  I extends {},
  R extends {
    [key: string]: (arg: State & I, action: PayloadAction<any>) => any;
  }
>(params: Params<I, R> = {}): AsyncActionsResult<I, R> {
  const mounted = React.useRef(true);
  const { initialState: initial, stateReducer }: Params<I, R> = params;
  const [state, dispatch] = React.useReducer(
    makeReducer<I>({ initialState: initial, stateReducer }),
    {
      ...initialState,
      ...initial,
    } as State & I,
  );

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const request = React.useCallback(
    payload => mounted.current && dispatch({ type: "request", payload }),
    [dispatch, mounted],
  );
  const success = React.useCallback(
    payload => mounted.current && dispatch({ type: "success", payload }),
    [dispatch, mounted],
  );
  const successRaw = React.useCallback(
    payload => mounted.current && dispatch({ type: "successRaw", payload }),
    [dispatch, mounted],
  );
  const failure = React.useCallback(
    payload => mounted.current && dispatch({ type: "failure", payload }),
    [dispatch, mounted],
  );
  const additionalReducers: { [key in FunctionKeys<R>]: (arg?: any) => void } = React.useMemo(
    () =>
      Object.entries(stateReducer || {}).reduce<any>((acc, [key, val]) => {
        acc[key] = (payload: any) => mounted.current && dispatch({ type: key, payload });
        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, mounted],
  );

  return tuplify(state as Assign<State, I>, {
    success,
    successRaw,
    request,
    failure,
    dispatch,
    ...additionalReducers,
  });
}

export function useDidMount(callback: () => any) {
  const initialMount = React.useRef(true);
  React.useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
      const res = callback();
      if (typeof res === "function") {
        return res;
      }
    }
  }, [callback]);
}

export function useBindActions<A extends {}>(actions: A, dispatch: any) {
  const boundActions = React.useMemo(() => {
    function bindActionCreator(
      actionCreator: (...args: any[]) => any,
      dispatcher: typeof dispatch,
    ) {
      return function(this: any) {
        return dispatcher(actionCreator.apply(this as any, (arguments as unknown) as any[]));
      };
    }
    const newActions = Object.keys(actions).reduce((acc, actionName) => {
      // @ts-ignore
      acc[actionName] = bindActionCreator(actions[actionName], dispatch);
      return acc;
    }, {} as { [k: string]: (...args: any[]) => any });
    // @ts-ignore
    return newActions as typeof actions;
    // eslint-disable-next-line
  }, [dispatch]);
  return boundActions;
}

export type ToggleHookState = {
  isOpen: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  toggle: () => void;
  close: () => void;
  open: () => void;
};

export const useToggle = (initial: boolean = false): ToggleHookState => {
  const [state, setState] = React.useState(initial);
  const toggle = useCallback(() => setState(value => !value), []);
  const close = useCallback(() => setState(false), []);
  const open = useCallback(() => setState(true), []);
  return useMemo(() => ({ isOpen: state, setState, toggle, close, open }), [
    close,
    open,
    state,
    toggle,
  ]);
};

/**
 * Toggle hook with doubled state, which changes after X milliseconds
 */
export const useThresholdToggle = (): {
  isOpen: boolean;
  shouldBeOpen: boolean;
  toggleOpen: () => void;
} => {
  const isNavbarOpen = useSelector(state => state.ui.isNavbarOpened);
  const [dispatch, { ui }] = useRedux();
  const isWarehouseSchema = useRouteMatch(`/wms/warehouse-schema`);
  const isOpen = isWarehouseSchema ? false : isNavbarOpen;
  const [shouldBeOpen, setShouldBeOpen] = useState(isOpen);
  const toggleMenu = useCallback(() => dispatch(ui.toggleNavbar()), [dispatch, ui]);
  const initialRender = useRef(true);

  const toggleOpen = () => {
    if (isWarehouseSchema) return;
    setShouldBeOpen(s => !s);
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    toggleMenu();
  }, [shouldBeOpen, toggleMenu]);

  const {
    shouldBeOpen: defaultShouldBeOpen,
    isOpen: defaultIsOpen,
    toggleOpen: defaultToggleOpen,
  } = useWithDefaultNavigationCollapse({
    useThresholdToggleReturn: {
      isOpen: isNavbarOpen,
      shouldBeOpen,
      toggleOpen,
    },
  });

  return {
    isOpen: defaultIsOpen,
    shouldBeOpen: defaultShouldBeOpen,
    toggleOpen: defaultToggleOpen,
  };
};

interface UseAsyncState {
  inProgress: boolean;
  error: null | { [key: string]: string };
}
/**
 * (deprecated) Hook catches and stores async request status and error
 * @example
 *   const [confirm, statuses, callbacks] = useAsyncStatus(confirmOrder);
		 const { inProgress, error, args } = statuses;

		 React.useEffect(() => {
			 const [parameters] = args;
			 if (callbacks.success) {
				 updateOrder({ isConfirmed: parameters.isConfirmed });
			 }
			if (callbacks.failure) {
				if (error) {
					alert(error.message || error.detail || "Wystąpił błąd");
				}
			}
		}, [callbacks.success, callbacks.failure, error, args, updateOrder]);
 */
export function useAsyncStatus<Call extends (arg?: any, arg2?: any) => any>(
  apiCall: Call,
  opts?: {
    cacheController?: boolean;
    onSuccess?: (response: InferResult<Call>, request: Parameters<Call>) => void;
    onFailure?: (
      response: {
        [key: string]: string;
      },
      request: Parameters<Call>,
    ) => void;
  },
) {
  const options = {
    cacheController: false,
    onSuccess: () => {},
    onFailure: () => {},
    ...opts,
  };
  const cache = {};
  const [state, setState] = React.useState<UseAsyncState>({
    inProgress: false,
    error: null,
  });
  const isMounted = useRef(true);
  const apiCallWrapper = useCallback(
    (...args: Parameters<Call>) => {
      return new Promise(async resolve => {
        // TODO: CACHE
        // @ts-ignore
        if (options.cacheController && cache[args[0]]) {
          return resolve(
            // @ts-ignore
            tuplify(cache[args[0]], null, {
              status: 200,
              isCanceled: false,
            }),
          );
        }
        setState({ ...state, inProgress: true });
        const res = await apiCall(...args);
        const [payload, error, { isCanceled, status }] = res;
        if (isCanceled || !isMounted.current) return;
        if (error) {
          setState({ ...state, inProgress: false, error: { ...error, status } });
          options.onFailure(error, args);
        } else {
          // @ts-ignore
          cache[args[0]] = payload;
          setState({
            ...state,
            inProgress: false,
            error: null,
          });
          options.onSuccess(payload, args);
        }
        return resolve(res);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiCall],
  );
  const setStatuses = useCallback((update: React.SetStateAction<Partial<State>>) => {
    if (typeof update === "function") {
      setState(prevState => ({ ...prevState, ...update(prevState) }));
    } else {
      setState(prevState => ({ ...prevState, ...update }));
    }
  }, []);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  // React.useEffect(() => {
  //   if (previousState.inProgress === true && state.inProgress === false) {
  //     if (state.error) {
  //       setCallbacks({ ...callbacks, success: false, failure: true });
  //     } else {
  //       setCallbacks({ ...callbacks, success: true, failure: false });
  //     }
  //   } else if (previousState.inProgress === false && state.inProgress === true) {
  //     setCallbacks({ ...callbacks, success: false, failure: false });
  //   }
  // }, [state, previousState, callbacks]);
  return tuplify(apiCallWrapper as Call, state, { setStatuses });
}

export const useQueryUtils = () => {
  const queryClient = useQueryClient();
  const toastr = useToastr();

  /**
	 * Optimistically update query cache from given key
	 * @returns previous query cache value
	 * @example
				const updateMutation = useMutation(
					(toUpdate: Partial<PriceList>) => patchPriceList(id, toUpdate),
					{
						onMutate: toUpdate => handleMutate([priceListsKeys.priceList, String(id)], toUpdate),
						onError: (error, toUpdate, prev) =>
							rollback([priceListsKeys.priceList, String(id)], prev, error),
					},
				);
			);
	 */
  const handleMutate = useCallback(
    <T>(key: QueryKey, toUpdate: Partial<T> | ((draft: T) => void)) => {
      // Snapshot the previous value
      const prev = queryClient.getQueryData<T>(key);

      // Optimistically update to the new value
      if (prev) {
        const value = typeof toUpdate === "function" ? immer(prev, toUpdate) : toUpdate;
        queryClient.setQueryData<T>(key, { ...prev, ...value });
      } else {
        throw new Error("Unexpected behavior: there is no query to mutate.");
      }

      return prev;
    },
    [queryClient],
  );

  /**
   * Optimistically update query cache paginated list from given key
   * @returns list element or null
   */
  const handlePaginatedListUpdate = <
    T extends { id: string | number },
    U = Partial<T> | ((draft: T) => void)
  >(
    key: QueryKey,
    id: number | string,
    toUpdate: U,
  ): T | null => {
    let prev = null;

    // Optimistically update to the new value
    queryClient.setQueriesData<any>(key, (currentList: Pagination<T>) => {
      if (!currentList) return;
      const listItem = currentList.results.find(el => el.id === id);

      if (listItem) {
        prev = listItem;
        return immer(currentList, draft => {
          const listItemToUpdate = draft.results.find(el => el.id === id);
          if (listItemToUpdate) Object.assign(listItemToUpdate, toUpdate);
        });
      } else {
        return currentList;
      }
    });
    return prev;
  };

  const handleSettled = (key: QueryKey) => {
    queryClient.invalidateQueries(key);
  };

  /**
   * if you pass @param error, toastr will be shown
   */
  const rollback = (key: QueryKey, prev: any, error?: QueryFetchError) => {
    queryClient.setQueryData<any>(key, prev);

    if (error) {
      if (error.response?.status === 500) {
        toastr.open({
          type: "failure",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      } else {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      }
    }
  };

  const rollbackList = (
    key: QueryKey,
    prev: { [x: string]: any } | null | undefined,
    id: number | string,
    error?: QueryFetchError,
  ) => {
    if (error) {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    }
    if (prev === null || prev === undefined) return;
    queryClient.setQueriesData<any>(key, (currentList: Pagination<any>) => {
      if (!currentList) return;
      const listItem = currentList.results.find(el => el.id === id);

      if (listItem) {
        return immer(currentList, draft => {
          const listItemToUpdate = draft.results.find(el => el.id === id);
          Object.assign(listItemToUpdate, prev);
        });
      } else {
        return currentList;
      }
    });
  };

  return {
    handleMutate,
    handlePaginatedListUpdate,
    handleSettled,
    rollback,
    rollbackList,
  };
};

export const useSettings = () => useSelector(state => state.settings.settings!);
export const useSalesSettings = () =>
  useSelector(state => {
    if (FLAVOR === "b2b") {
      return state.settings.settings!.sales.b2b;
    }
    return state.settings.settings!.sales;
  });

export const useRightPanelDisplaySettings = () =>
  useSelector(state => {
    if (FLAVOR === "b2b") {
      return state.settings.settings!.sales.b2b.rightPanelDisplay;
    }
    return state.settings.settings!.sales.rightPanelDisplay;
  });

export function useDebounce<T>(val: T, time: number = 300) {
  const [state, setState] = useState<T>(val);

  const throttled = useRef<typeof setState>(debounce(time, setState));

  useEffect(() => {
    throttled.current(val);
  }, [val]);
  return state;
}

export function useUserAvailableModules(): Record<Module, ModuleNavigation> {
  const me = useSelector(store => store.auth.user);
  const availableModules = {} as Record<Module, ModuleNavigation>;
  dictToList(moduleConfig).forEach(({ key, value }) => {
    const correspondingModule = me?.modules.find(module => module.name === value.name);
    if (correspondingModule?.hasAccess) {
      availableModules[key] = value;
      return;
    }
  });

  return availableModules;
}
