import { orderFileFactory } from "api/orders/calls";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { Order } from "./models";
import { StatusHandlerHelpers } from "components/utils";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { useErrorToastr } from "hooks/useErrorToastr";
import { fileDownloader } from "fileDownloader";
import { createApiQuery } from "hooks/createApiQuery";
import { orderApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";

const useSingleItemQuantityWarehouseLocations = createPaginatedApiQuery(
  orderApi.getSingleItemQuantityWarehouseLocations,
);

const useGetOrdersFreeSingleItemQuantities = createPaginatedApiQuery(
  orderApi.getOrdersFreeSingleItemQuantities,
);

const useGetOrderManufacturingSingleItemQuantities = createPaginatedApiQuery(
  orderApi.getOrderManufacturingSingleItemQuantities,
);
const useGetOrderFreeSingleItemsQuantitiesDetails = createApiQuery(
  orderApi.getOrderFreeSingleItemsQuantitiesDetails,
);

const useOrdersGroupByInfo = createApiQuery(orderApi.getOrdersGroupByInfo);

const useOrdersForInvoicing = createPaginatedApiQuery(orderApi.getOrdersForInvoicing);

const useManufacturingOrderDetails = createApiQuery(orderApi.getManufacturingOrderDetails);

const useOrdersSalesAccountInfo = createApiQuery(orderApi.getOrdersSalesAccountInfo);

const useManufacturingOrders = createPaginatedApiQuery(orderApi.getManufacturingOrders);
const useDownloadOrderSpecificationPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (order: Order, helpers: StatusHandlerHelpers) => {
    helpers.startFetching();
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = orderFileFactory.orderSpecificationPdf(order);
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    helpers.stopFetching();
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const usePostAwaitingTransport = () => {
  return useMutation(orderApi.postAwaitingTransport, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utwórzono awizację",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

export const orderActions = {
  useSingleItemQuantityWarehouseLocations,
  useDownloadOrderSpecificationPdf,
  useGetOrdersFreeSingleItemQuantities,
  useGetOrderFreeSingleItemsQuantitiesDetails,
  useOrdersForInvoicing,
  useOrdersGroupByInfo,
  useOrdersSalesAccountInfo,
  useManufacturingOrderDetails,
  useManufacturingOrders,
  useGetOrderManufacturingSingleItemQuantities,
  usePostAwaitingTransport,
};
