import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import infoSuccessIcon from "assets/images/infoSuccess.svg";
import { AddOrdersToUnloadingResponse } from "api/wms/models";
import { cx, pluralize } from "utilities";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import styles from "./AddOrdersBySignaturesModal.module.css";
import { Link } from "components/miloDesignSystem/atoms/link";

interface Props {
  close: () => void;
  state: AddOrdersToUnloadingResponse;
}

export const ResponseModal = ({ close, state }: Props) => {
  const hasInvalidOrders = (): boolean => {
    return (
      Boolean(state.inDifferentUnloading.length) ||
      Boolean(state.missingIndexes.length) ||
      Boolean(state.missingOrders.length) ||
      Boolean(state.missingPackages.length)
    );
  };

  return (
    <Modal close={close} isOpen removeHeader width={430}>
      <>
        <div className={cx("d-flex justify-content-between p-3", styles.successTitle)}>
          <div className="d-flex align-items-center">
            <div>
              <img alt="sukces" src={infoSuccessIcon} />
            </div>
            <Typography color="neutralWhite100" fontSize="20" fontWeight="700">
              Dodano zamówienia do rozładunku
            </Typography>
          </div>
          <IconButton
            icon={<MdiClose color="neutralWhite88" size="16" />}
            onClick={close}
            variant="transparent"
          />
        </div>
        <div className="d-flex flex-column p-3 gap-2">
          {Boolean(state.addedToUnloading.length) && (
            <div className="d-flex align-items-center gap-1 mb-1">
              <Typography color="success500" fontSize="16" fontWeight="700">
                Dodano {state.addedToUnloading.length}{" "}
                {pluralize.pl(state.addedToUnloading.length, {
                  singular: "zamówienie",
                  plural: "zamówienia",
                  other: "zamówień",
                })}
                .
              </Typography>
              {state.createdUnloading && (
                <div className="d-flex align-items-center gap-1">
                  <Typography color="success500" fontSize="16" fontWeight="700">
                    Utworzono:
                  </Typography>
                  <Link
                    fontSize="16"
                    fontWeight="700"
                    target="_blank"
                    to={`/wms/unloadings/list/all?panelId=${state.createdUnloading.id}`}
                  >
                    {state.createdUnloading.signature}
                  </Link>
                </div>
              )}
            </div>
          )}
          {hasInvalidOrders() && (
            <div>
              <Typography color="warning500" fontSize="18" fontWeight="700">
                To są zamówienia, których nie dodano:
              </Typography>
              <div className="d-flex flex-column gap-2 pb-3 pt-2">
                {Boolean(state.inDifferentUnloading.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Już w innym rozładunku:
                    </Typography>
                    <div className="d-flex flex-column gap-1">
                      {state.inDifferentUnloading.map(order => (
                        <div
                          className="d-flex align-items-center flex-wrap gap-2"
                          key={order.order.id}
                        >
                          <Typography fontSize="16" fontWeight="600">
                            {order.order.signature}
                          </Typography>
                          {Boolean(order.order.externalId) && (
                            <Typography fontSize="14" fontWeight="500">
                              ({order.order.externalId})
                            </Typography>
                          )}
                          {order.unloading && (
                            <Link
                              fontSize="14"
                              fontWeight="600"
                              target="_blank"
                              to={`/wms/unloadings/list/all?panelId=${order.unloading.id}`}
                            >
                              {order.unloading.signature}
                            </Link>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {Boolean(state.missingIndexes.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Brak indeksów w zamówieniu:
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.missingIndexes
                        .map(
                          order =>
                            `${order.signature}${order.externalId && ` (${order.externalId})`}`,
                        )
                        .join(", ")}
                    </Typography>
                  </div>
                )}
                {Boolean(state.missingOrders.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Brak zamówień:
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.missingOrders
                        .map(
                          order =>
                            `${order.signature}${order.externalId && ` (${order.externalId})`}`,
                        )
                        .join(", ")}
                    </Typography>
                  </div>
                )}
                {Boolean(state.missingPackages.length) && (
                  <div>
                    <Typography fontSize="16" fontWeight="700">
                      Brak paczek w zamówieniu:
                    </Typography>
                    <Typography fontSize="16" fontWeight="500">
                      {state.missingPackages
                        .map(
                          order =>
                            `${order.signature}${order.externalId && ` (${order.externalId})`}`,
                        )
                        .join(", ")}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          )}
          <div>
            <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
              Zamknij
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};
