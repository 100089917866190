import { ToggleHookState } from "hooks";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Button } from "components/miloDesignSystem/atoms/button";
import { assertIsDefined } from "utilities/assertIsDefined";
import { CommonError, FormInput } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { customerActions } from "api/customers/actions";
import { GusDetails } from "api/customers/models";
import { PropsWithChildren } from "react";
import { getAnyErrorKey } from "utilities";
import { Formik } from "formik";
import styles from "./GetDataFromGusModal.module.css";

interface Props {
  modal: ToggleHookState;
  taxId: string;
  onConfirmClick: (data: GusDetails) => void;
}

export const GetDataFromGusModal = ({ modal, taxId, onConfirmClick }: Props) => {
  const { data, isLoading, error } = customerActions.useCustomerGusDetails(taxId);

  if (error)
    return (
      <ModalLayout closeModal={modal.close}>
        <div className="p-5 d-flex align-items-center flex-1 justify-content-center">
          <CommonError text={getAnyErrorKey(error)} status={error._httpStatus_} />
        </div>
      </ModalLayout>
    );

  if (isLoading)
    return (
      <ModalLayout closeModal={modal.close}>
        <div className="p-5 d-flex align-items-center flex-1 justify-content-center">
          <Spinner size={30} />
        </div>
      </ModalLayout>
    );

  assertIsDefined(data);

  const customOnConfirmClick = (values: GusDetails) => {
    onConfirmClick(values);
    modal.close();
  };

  const initialValues: GusDetails = {
    fullName: data.fullName,
    name: data.name,
    taxId: data.taxId,
    postCode: data.postCode,
    city: data.city,
    street: data.street,
    businessRegisterNumber: data.businessRegisterNumber,
    ownerFirstName: data.ownerFirstName,
    ownerLastName: data.ownerLastName,
    phone: data.phone,
    email: data.email,
  };

  return (
    <ModalLayout closeModal={modal.close} taxId={data.taxId}>
      <div className="px-3 pt-0 pb-3">
        <Formik
          initialValues={initialValues}
          onSubmit={values => customOnConfirmClick(values)}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <FormInput name="fullName" label="Pełna nazwa" />
                <FormInput name="name" label="Nazwa skrócona" />
                <FormInput name="ownerFirstName" label="Imię właściciela" />
                <FormInput name="ownerLastName" label="Nazwisko właściciela" />
                <FormInput name="businessRegisterNumber" label="REGON" />
                <FormInput name="street" label="Adres siedziby" />
                <FormInput name="email" label="Email" />
                <div className="d-flex">
                  <div className={styles.inputShort}>
                    <FormInput name="postCode" label="Kod pocztowy" />
                  </div>
                  <div className={styles.inputLong}>
                    <FormInput name="city" label="Miasto" />
                  </div>
                </div>
                <FormInput name="phone" label="Telefon" />
                <div className="d-flex align-items-center gap-3 mt-4">
                  <Button
                    onClick={modal.close}
                    size="medium"
                    variant="transparent"
                    className="text-uppercase"
                  >
                    Anuluj
                  </Button>
                  <Button
                    type="submit"
                    size="medium"
                    variant="deepPurple"
                    className="text-uppercase"
                  >
                    Przenieś na formularz
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </ModalLayout>
  );
};

const ModalLayout = ({
  children,
  closeModal,
  taxId,
}: PropsWithChildren<{ closeModal: () => void; taxId?: string }>) => {
  return (
    <Modal
      close={closeModal}
      isOpen
      title={
        <div className="d-flex align-items-baseline gap-3">
          <Typography fontSize="20" fontWeight="700">
            Pobieranie danych z GUS
          </Typography>
          <Typography fontSize="20" fontWeight="700">
            {taxId}
          </Typography>
        </div>
      }
      width={741}
    >
      <div>{children}</div>
    </Modal>
  );
};
