import { useTradingDocuments } from "api/trading-documents/hooks";
import { CommonError, RightPanelHandler } from "components/utils";
import { ListDrawerWrapper } from "components/utils/drawer";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { receiptListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ListHeader } from "./components/ListHeader";
import { ListItem } from "./components/ListItem";
import { TradingDocumentTab } from "api/trading-documents/models";
import { getSearch } from "../shared/utils/getSearch";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { RightPanel } from "pages/tradingDocuments/receiptsList/rightPanel/RightPanel";
import { NoResults } from "components/utils/noResults";
import { Loader } from "components/utils/loader";
import { useState } from "react";
import { HighlightedRow } from "api/other/models";
import { UUID } from "api/types";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { ActionToolbar } from "../shared/components/actionToolbar/ActionToolbar";
import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { FilterDrawerRenderer } from "components/common/moduleNavigation/components/filtersSection/FilterDrawerRenderer";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";

export const ReceiptsList = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const type = "RECEIPT";
    const additionalListParams = {
      type,
    };
    const search = getSearch({ query, type, tab });
    const {
      data: receipts,
      error,
      isFetching,
      isLoading,
      refetch,
      pagination,
    } = useTradingDocuments(search);

    const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);

    const noResults = !receipts.length && !isLoading && !error;

    if (error) {
      return (
        <div>
          <ListHeader additionalListParams={additionalListParams} refetchList={refetch} />
          <div className="mt-4">
            <CommonError status={error._httpStatus_} />
          </div>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <Loader module="finances" />
          <ListHeader additionalListParams={additionalListParams} refetchList={refetch} />
        </div>
      );
    }

    const selectMutlipleItems = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      id: UUID,
      index: number,
      togglePanel: () => void,
    ) => {
      handleMultipleRowsHighlight(
        event,
        highlightedRows,
        id,
        index,
        setHighlightedRows,
        togglePanel,
        receipts,
      );
    };

    return (
      <PageWrapper>
        {isFetching && <Loader module="finances" />}
        <ListHeader additionalListParams={additionalListParams} refetchList={refetch} />
        <RightPanelHandler name="receipts">
          {({ close, isActive, isHighlighted, togglePanel }) => (
            <ListDrawerWrapper>
              {noResults ? (
                <NoResults on={noResults} subHeader="paragony" />
              ) : (
                <ModuleList>
                  <ModuleListHeader
                    gridStyle={receiptListConfig.grid}
                    labels={receiptListConfig.labels}
                  />
                  <ModuleListBody>
                    {receipts.map((receipt, index) => (
                      <ListItem
                        highlightedRows={highlightedRows}
                        index={index}
                        isActive={isActive}
                        isHighlighted={isHighlighted}
                        key={receipt.id}
                        receipt={receipt}
                        selectMutlipleItems={selectMutlipleItems}
                        togglePanel={togglePanel}
                      />
                    ))}
                  </ModuleListBody>
                </ModuleList>
              )}
              <RightPanel close={close} />
              <FilterDrawerRenderer />
              <InvoicePagination pagination={pagination} />
              {highlightedRows.length > 0 && (
                <ActionToolbar
                  close={() => {
                    setHighlightedRows([]);
                  }}
                  quantity={highlightedRows.length}
                  highlightedRows={highlightedRows}
                />
              )}
            </ListDrawerWrapper>
          )}
        </RightPanelHandler>
      </PageWrapper>
    );
  },
);
