import styled from "@emotion/styled";
import { cx } from "utilities";
import { EmptySectionProps } from "./types";
import { MdiFolderOpen } from "components/miloDesignSystem/atoms/icons/MdiFolderOpen";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";

export const EmptySection = ({
  AdditionalInfoRenderer,
  className,
  label,
  icon: Icon,
}: EmptySectionProps) => {
  return (
    <div className={cx("pl-3 pr-3", className)}>
      <StyledEmptySection>
        {Icon && typeof Icon === "function" ? (
          <Icon color="neutralBlack48" />
        ) : Icon ? (
          Icon
        ) : (
          <MdiFolderOpen color="neutralBlack48" />
        )}
        <Typography className="pt-2" fontSize="12" fontWeight="700">
          {label}
        </Typography>
        {AdditionalInfoRenderer}
      </StyledEmptySection>
    </div>
  );
};

const StyledEmptySection = styled.div`
  border: 1px solid var(--neutralBlack16);
  border-radius: 4px;
  padding: 20px;
`;
